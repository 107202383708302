/**
 * Push ALert cart update
 * @param {Object} auth auth instance
 * @param {Object} totalItems { items <Array> }
 */
export const abandonedCartUpdate = (auth, item, totalItems) => {
  window.pushalertbyiw = window.pushalertbyiw || []
  const userName = auth.user ? ' ' + auth.user.first_name : ''

  window.pushalertbyiw.push([
    'abandonedCart',
    'add-to-cart',
    {
      product_name: item.product.title,
      image: item.product.media ? item.product.media.cf_path : '',
      first_name: userName,
      total_items: totalItems,
    },
  ])
}
export const abandonedCartCompleted = () => {
  window.pushalertbyiw = window.pushalertbyiw || []
  window.pushalertbyiw.push(['abandonedCart', 'completed'])
}
