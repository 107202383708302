import { cartProductForDataLayer } from '@/plugins/utilities'
function clearDataLayer() {
  window.dataLayer.push({ ecommerce: null })
}

/**
 * GTM Product Impression
 * @param {Object} gtm GTM instance
 * @param {Object} data { products <Array> }
 */
export const viewItemList = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_name: data.list,
      items: data.products.map((product, productIndex) => ({
        item_id: product.sku,
        item_name: product.title,
        coupon: product.undiscounted_price ? 'Standart Discount' : null,
        discount:
          product.undiscounted_price > product.price
            ? product.undiscounted_price - product.price
            : 0,
        affiliation: 'Rydale',
        index: productIndex,
        item_brand: product.vendor,
        item_category: product.product_type,
        item_category2: product.age_group,
        item_category3: product.gender,
        item_variant: product.variant_title,
        price: product.price,
      })),
    },
  })
}

/**
 * GTM Product Impression
 * @param {Object} data { }
 */
export const addToCart = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: data.currency || 'GBP',
      value: data.subtotal,
      items: [
        {
          item_id: data.product_variant_sku,
          item_name: data.product_title,
          affiliation: data.store || 'Rydale',
          coupon: data.discount_name,
          discount: data.discount_total,
          index: 0,
          item_brand: data.product.vendor,
          item_category: data.product.product_type,
          item_category2: data.product.age_group,
          item_category3: data.product.gender,
          item_variant: data.product_variant_title,
          price: data.price,
          quantity: data.quantity,
        },
      ],
    },
  })
}

/**
 * GTM Product Impression
 * @param {Object} data { }
 */
export const addToWishlist = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: data.currency || 'GBP',
      value: data.price,
      items: [
        {
          item_id: data.sku,
          item_name: data.title,
          affiliation: data.store || 'Rydale',
          coupon: data.undiscounted_price ? 'Standart Discount' : null,
          discount:
            data.undiscounted_price > data.price
              ? data.undiscounted_price - data.price
              : 0,
          index: 0,
          item_brand: data.vendor,
          item_category: data.product_type,
          item_category2: data.age_group,
          item_category3: data.gender,
          item_variant: data.variant_title,
          price: data.price,
        },
      ],
    },
  })
}

/**
 * GTM Product Impression
 * @param {Object} data { products <Array> }
 */
export const removeFromCart = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: data.currency || 'GBP',
      value: data.price,
      items: [
        {
          item_id: data.product_variant_sku,
          item_name: data.product_title,
          affiliation: data.store || 'Rydale',
          coupon: data.discount_name,
          discount: data.discount_total,
          index: 0,
          item_brand: data.product.vendor,
          item_category: data.product.product_type,
          item_category2: data.product.age_group,
          item_category3: data.product.gender,
          item_variant: data.product_variant_title,
          price: data.price,
          quantity: data.quantity,
        },
      ],
    },
  })
}

/**
 * GTM Product Click
 * @param {Object} data { product, index }
 */
export const selectItem = (product) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: product.id,
          item_name: product.title,
          affiliation: 'Rydale',
          coupon: product.undiscounted_price ? 'Standart Discount' : null,
          discount:
            product.undiscounted_price > product.price
              ? product.undiscounted_price - product.price
              : 0,
          index: product.index,
          item_brand: 'Google',
          item_category: product.product_type,
          item_category2: product.age_group,
          item_category3: product.gender,
          item_variant: product.variant_title,
          price: product.price,
        },
      ],
    },
  })
}

/**
 * GTM Product Click
 * @param {Object} data { product }
 */
export const viewItem = (product) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: product.currency,
      value: product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.title,
          affiliation: product.store || 'Rydale',
          coupon: product.undiscounted_price ? 'Standart Discount' : null,
          discount:
            product.undiscounted_price > product.price
              ? product.undiscounted_price - product.price
              : 0,
          index: 0,
          item_brand: product.vendor,
          item_category: product.product_type,
          item_category2: product.age_group,
          item_category3: product.gender,
          item_variant: product.variant_title,
          price: product.price,
        },
      ],
    },
  })
}

/**
 * GTM Product Click
 * @param {Object} data { order }
 */
export const viewCart = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'view_cart',

    ecommerce: {
      currency: data.currency,
      value: data.subtotal,
      items: data.orderProducts.map((product, productIndex) => ({
        item_id: product.product_variant_sku,
        item_name: product.product_title,
        affiliation: data.store || 'Rydale',
        coupon: product.discount_name,
        discount: product.discount_total,
        index: productIndex,
        item_brand: product.product.vendor,
        item_category: product.product.product_type,
        item_category2: product.product.age_group,
        item_category3: product.product.gender,
        item_variant: product.product_variant_title,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

/**
 * GTM Checkout Step
 * @param {Object} param1 { data }
 */
export const beginCheckout = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'begin_checkout',

    ecommerce: {
      currency: data.currency,
      value: data.subtotal,
      coupon: data.discount_code
        ? data.discount_code
        : data.gift_cards.length
        ? data.gift_cards[0].code
        : null,
      items: data.orderProducts.map((product, productIndex) => ({
        item_id: product.product_variant_sku,
        item_name: product.product_title,
        affiliation: data.store || 'Rydale',
        coupon: product.discount_name,
        discount: product.discount_total,
        index: productIndex,
        item_brand: product.product.vendor,
        item_category: product.product.product_type,
        item_category2: product.product.age_group,
        item_category3: product.product.gender,
        item_variant: product.product_variant_title,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

export const addShippingInfo = (data) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'add_shipping_info',

    ecommerce: {
      currency: data.currency,
      value: data.subtotal,
      coupon: data.discount_code
        ? data.discount_code
        : data.gift_cards.length
        ? data.gift_cards[0].code
        : null,
      shipping_tier: data.selectedShipping.estimated_delivery_time,
      items: data.orderProducts.map((product, productIndex) => ({
        item_id: product.product_variant_sku,
        item_name: product.product_title,
        affiliation: data.store || 'Rydale',
        coupon: product.discount_name,
        discount: product.discount_total,
        index: productIndex,
        item_brand: product.product.vendor,
        item_category: product.product.product_type,
        item_category2: product.product.age_group,
        item_category3: product.product.gender,
        item_variant: product.product_variant_title,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

/**
 * GTM Purchase
 * @param {Object} gtm GTM instance
 * @param {Object} param1 { order, orderProducts }
 */
export const purchaseGtm = ({ order, orderProducts }) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currencyCode: order.currency || 'GBP',
      purchase: {
        actionField: {
          id: order.id,
          tax: order.tax_total,
          shipping: order.shipping_total,
          coupon: order.discount_code,
          revenue: order.total,
          payment_type: order.string_payment_method,
        },
        products: orderProducts.map(cartProductForDataLayer),
      },
    },
  })
  window.dataLayer.push({
    event: 'conversion',
    send_to: 'AW-10847318695/GKdnCITvhuQYEKfls7Qo',
    value: order.total,
    currency: order.currency,
    transaction_id: order.id,
    user_data: {
      email_address: order.email,
    },
  })
}

/**
 * GTM Purchase
 * @param {Object} gtm GTM instance
 * @param {Object} param1 { order, orderProducts }
 */
export const purchaseGa4 = ({ order, orderProducts }) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: order.id,
      value: order.subtotal,
      tax: order.taxes_total,
      shipping: order.shipping_total,
      currency: order.currency,
      coupon: order.discount_code
        ? order.discount_code
        : order.gift_cards.length
        ? order.gift_cards[0].code
        : null,

      items: orderProducts.map((product, productIndex) => ({
        item_id: product.product_variant_sku,
        item_name: product.product_title,
        affiliation: order.store || 'Rydale',
        coupon: product.discount_name,
        discount: product.discount_total,
        index: productIndex,
        item_brand: product.product.vendor,
        item_category: product.product.product_type,
        item_category2: product.product.age_group,
        item_category3: product.product.gender,
        item_variant: product.product_variant_title,
        price: product.price,
        quantity: product.quantity,
      })),
    },
  })
}

/**
 * GTM Sign in page
 * @param {String} event Event type for GTM
 */
export const searchInfo = (searchTerm) => {
  clearDataLayer()

  window.dataLayer.push({
    event: 'search',
    search_term: searchTerm,
  })
}

/**
 * GTM Sign in page
 * @param {String} eventName Event type for GTM
 */
export const SignInGTM = (eventName) => {
  clearDataLayer()
  window.dataLayer.push({
    event: 'login',
    method: eventName,
  })
}

/**
 * GTM Sign in page
 * @param {String} eventName Event type for GTM
 */
export const signUpGTM = (eventName) => {
  clearDataLayer()
  window.dataLayer.push({
    event: 'sign_up',
    method: eventName,
  })
}

/**
 * GTM Virtual Page View
 * @param {Object} gtm GTM instance
 * @param {Object} route Page route instance
 * @param {String} modelType Page model <'product' | 'collection' | 'page'>
 * @param {String} pageTitle document.title
 */
export const virtualPageView = (route, modelType, pageTitle) => {
  let pageType
  switch (modelType) {
    case 'product':
      pageType = 'Product Page'
      break
    case 'collection':
      pageType = 'Collection Page'
      break
    case 'page':
      pageType = 'Other'
      break
    default:
      pageType = modelType
      break
  }

  window.dataLayer.push({
    event: 'virtualPageView',
    pageType,
    pageUrl: route.fullPath,
    pageTitle,
  })
}

/**
 * GTM Product List Page View
 * @param {Object} gtm GTM instance
 * @param {Number} pageNumber Loaded page number
 * @param {Number} totalPages Total page count
 */
export const productListPageView = (pageNumber, totalPages) => {
  window.dataLayer.push({
    event: 'productListPageView',
    pageNumber,
    totalPages,
  })
}
