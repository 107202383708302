import Vue from 'vue'

export default ({ app }) => {
  Vue.filter('money', (str) => {
    if (str) {
      if (app.store.getters['settings/currency'] === 'EUR') {
        let fractionDigits = 0
        if (
          Number.parseFloat(str) < Number.parseInt(str) ||
          Number.parseFloat(str) > Number.parseInt(str)
        ) {
          fractionDigits = 2
        }

        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
          currency: 'EUR',
        }).format(str)
      } else if (app.store.getters['settings/currency'] === 'USD') {
        let fractionDigits = 0
        if (
          Number.parseFloat(str) < Number.parseInt(str) ||
          Number.parseFloat(str) > Number.parseInt(str)
        ) {
          fractionDigits = 2
        }

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
          currency: 'USD',
        }).format(str)
      } else {
        let fractionDigits = 0
        if (
          Number.parseFloat(str) < Number.parseInt(str) ||
          Number.parseFloat(str) > Number.parseInt(str)
        ) {
          fractionDigits = 2
        }

        return new Intl.NumberFormat('en-GB', {
          style: 'currency',
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
          currency: 'GBP',
        }).format(str)
      }
    }
  })

  Vue.filter('moneyForGiftcard', (str) => {
    if (app.store.getters['settings/currency'] === 'EUR') {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        notation: 'compact',
      }).format(str)
    } else if (app.store.getters['settings/currency'] === 'USD') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: 'compact',
      }).format(str)
    } else {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        notation: 'compact',
      }).format(str)
    }
  })

  Vue.filter('moneyLanguage', (str, language) => {
    if (app.i18n.locale === 'en') {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(str)
    } else if (app.i18n.locale === 'us') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(str)
    } else {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(str)
    }
  })
}
