export default function ({ app }) {
  window.dataLayer = window.dataLayer || []

  // GTM: originalLocation
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })

  const pageView = (route) => {
    console.log(
      '%cPAGE VIEW',
      'background-color: #5e23eb; color: #fff; font-weight: bold; font-size: 10px; padding: 4px 6px; border-radius: 4px;',
      ' ' + route.name
    )

    const gtm = (type) => {
      window.dataLayer.push({
        event: 'virtualPageView',
        pageType: type,
        pageUrl: route.fullPath,
        pageTitle: document && document.title,
      })
    }

    const name = route.name ? route.name.split('___')[0] : ''

    // GTM: virtualPageView
    switch (name) {
      case 'index':
        gtm('Homepage')
        break
      case 'slug':
      case 'collections-slug':
      case 'products-slug':
      case 'checkout':
      case 'checkout-info':
      case 'checkout-delivery':
      case 'checkout-payment':
        // Do nothing
        break
      case 'men':
      case 'children':
      case 'sales':
      case 'blog-news':
      case 'blog-news-slug':
      case 'cart':
        gtm(
          name
            .split('-')
            .filter((x) => !Object.keys(route.params).includes(x))
            .map((x) => `${x.slice(0, 1).toUpperCase()}${x.slice(1)}`)
            .join(' ')
        )
        break
      default: {
        const routeNameWords = name.split('-')
        if (routeNameWords[0] === 'checkout') {
          gtm('Checkout Pages')
        } else if (routeNameWords[0] === 'wishlists') {
          gtm('Wishlist Pages')
        } else if (routeNameWords[0] === 'account') {
          gtm('Account Panel')
        } else {
          gtm('Other')
        }
        break
      }
    }
  }

  app.router.afterEach((to) => {
    setTimeout(() => {
      pageView(to)
    }, 250)
  })
}
