/**
 * GTM Purchase
 * @param {Object} param1 { order }
 */
export const purchaseMicrosoft = ({ order, orderProducts }) => {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'purchase', {
    transaction_id: order.order_payment_id,
    ecomm_prodid: orderProducts.map((product) => product.product_variant_id),
    ecomm_pagetype: 'purchase',
    ecomm_totalvalue: order.total,
    revenue_value: order.total,
    currency: order.currency || 'GBP',
    items: orderProducts.map((product) => {
      return {
        id: product.product_variant_id,
        quantity: product.quantity,
        price: product.price,
      }
    }),
  })
}

export const homepageMicrosoft = () => {
  window.uetq = window.uetq || []
  window.uetq.push('event', '', {
    ecomm_pagetype: 'home',
  })
}

export const categoryBrowsePageMicrosoft = ({ collection }) => {
  window.uetq = window.uetq || []
  window.uetq.push('event', '', {
    ecomm_category: collection.id,
    ecomm_prodid: collection.products.map((product) => product.id),
    ecomm_pagetype: 'category',
  })
}
export const searchResultsPageMicrosoft = (params) => {
  window.uetq = window.uetq || []
  window.uetq.push('event', '', {
    ecomm_query: params.query.q,
    ecomm_prodid: params.collection.products.map((product) => product.id),
    ecomm_pagetype: 'searchresults',
  })
}

export const productDisplayPageMicrosoft = (product) => {
  window.uetq = window.uetq || []
  window.uetq.push('event', '', {
    ecomm_prodid: [product.id],
    ecomm_pagetype: 'product',
  })
}
export const addToCartMicrosoft = ({ product, ...cart }) => {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'add_to_cart', {
    ecomm_prodid: [product.id],
    ecomm_pagetype: 'product',
    ecomm_totalvalue: product.price,
    revenue_value: product.price,
    currency: cart.currency || 'GBP',
    items: [
      {
        id: product.id,
        quantity: 1,
        price: product.price,
      },
    ],
  })
}

export const shoppingCartMicrosoft = (products) => {
  window.uetq = window.uetq || []
  window.uetq.push('event', '', {
    ecomm_prodid: products.orderProducts.map(
      (product) => product.product_variant_id
    ),
    ecomm_pagetype: 'cart',
    ecomm_totalvalue: products.total,
    revenue_value: products.total,
    currency: products.currency || 'GBP',
    items: products.orderProducts.map((product) => {
      return {
        id: product.product_variant_id,
        quantity: product.quantity,
        price: product.per_product_price,
      }
    }),
  })
}
