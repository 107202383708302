import { SyncLocalStorage } from '@/plugins/syncLocalStorage'
import { wishlistParams, cartParams } from '@/plugins/utilities'

export const state = () => ({
  sessionId: null,
  items: [],
  isLoading: false,
})

export const getters = {
  total(state) {
    return state.items.reduce((p, x) => x.quantity + p, 0)
  },

  loading(state) {
    return state.isLoading
  },
}

export const mutations = {
  setLoading(state, loading) {
    state.isLoading = loading
  },

  set(state, items) {
    state.items = items
  },

  setSessionId(state, sessionId) {
    state.sessionId = sessionId
  },
}

export const actions = {
  // add this method to layout's mount hook
  async browserInit({ commit, dispatch }) {
    let sessionId = await SyncLocalStorage('wishlist.sessionId')

    if (!sessionId) {
      sessionId = Math.random().toString(36).substr(2, 31)
    }

    commit('setSessionId', sessionId)

    dispatch('merge')
  },

  addToWishlist({ commit, state }, product) {
    commit('setLoading', true)

    return this.$axios
      .$post('defaultWishlist/add', {
        ...cartParams(product),
        session_id: state.sessionId,
      })
      .then((result) => {
        const userItems = result.data
        commit('set', userItems)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  removeFromWishlist({ commit, state }, product) {
    commit('setLoading', true)

    return this.$axios
      .$post('defaultWishlist/remove', {
        ...wishlistParams(product),
        session_id: state.sessionId,
      })
      .then((result) => {
        const userItems = result.data
        commit('set', userItems)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  reset({ commit, state }) {
    commit('set', [])
  },

  async merge({ commit, state }) {
    commit('setLoading', true)

    return this.$axios
      .$post('defaultWishlist/merge', {
        products: (await SyncLocalStorage('wishlist.items')) || [],
        session_id: state.sessionId,
      })
      .then((result) => {
        commit('set', result.data)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  update({ commit, state }) {
    // TODO: implement after checkout
    commit('setLoading', true)
    return this.$axios
      .$get('defaultWishlist', {
        session_id: state.sessionId,
      })
      .then((result) => {
        const userItems = result.data
        commit('set', userItems)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
}
