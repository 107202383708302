export const state = () => ({
  searches: [],
})

export const getters = {
  recentSearches: (state) => state.searches,
}

export const mutations = {
  set(state, value) {
    state.searches = value
  },
  add(state, value) {
    const searchIndex = state.searches.indexOf(value)
    if (searchIndex > -1) {
      state.searches.splice(searchIndex, 1)
    }

    state.searches = [value, ...state.searches]
    localStorage.setItem('search', JSON.stringify(state.searches))
  },
  clear(state) {
    state.searches = []
    localStorage.setItem('search', JSON.stringify([]))
  },
}

export const actions = {
  browserInit({ commit }) {
    const search = JSON.parse(localStorage.getItem('search') || '[]')
    commit('set', search)
  },
  add({ commit }, value) {
    commit('add', value)
  },
  clear({ commit }) {
    commit('clear')
  },
}
