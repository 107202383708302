import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _07b969e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _4e64555e = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _15b911df = () => interopDefault(import('../pages/shopping-event.vue' /* webpackChunkName: "pages/shopping-event" */))
const _1066e1c9 = () => interopDefault(import('../pages/account/addresses/index.vue' /* webpackChunkName: "pages/account/addresses/index" */))
const _2efdd76a = () => interopDefault(import('../pages/account/change-email.vue' /* webpackChunkName: "pages/account/change-email" */))
const _17bf4f88 = () => interopDefault(import('../pages/account/change-password.vue' /* webpackChunkName: "pages/account/change-password" */))
const _09092a72 = () => interopDefault(import('../pages/account/edit-profile.vue' /* webpackChunkName: "pages/account/edit-profile" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _cfdcdcdc = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _9b880836 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _2f3a1846 = () => interopDefault(import('../pages/auth/validation.vue' /* webpackChunkName: "pages/auth/validation" */))
const _16adeb84 = () => interopDefault(import('../pages/blogs/news/index.vue' /* webpackChunkName: "pages/blogs/news/index" */))
const _ba494cba = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _7413567c = () => interopDefault(import('../pages/checkout/guest/index.vue' /* webpackChunkName: "pages/checkout/guest/index" */))
const _5724d8dd = () => interopDefault(import('../pages/checkout/info.vue' /* webpackChunkName: "pages/checkout/info" */))
const _affa8a2c = () => interopDefault(import('../pages/checkout/payment/index.vue' /* webpackChunkName: "pages/checkout/payment/index" */))
const _3a6d0e9a = () => interopDefault(import('../pages/pages/about-us.vue' /* webpackChunkName: "pages/pages/about-us" */))
const _5df69be6 = () => interopDefault(import('../pages/pages/ambassador.vue' /* webpackChunkName: "pages/pages/ambassador" */))
const _54638b62 = () => interopDefault(import('../pages/pages/childrens.vue' /* webpackChunkName: "pages/pages/childrens" */))
const _7254ef08 = () => interopDefault(import('../pages/pages/choose-country-region.vue' /* webpackChunkName: "pages/pages/choose-country-region" */))
const _591f2180 = () => interopDefault(import('../pages/pages/contact-us.vue' /* webpackChunkName: "pages/pages/contact-us" */))
const _c334a03c = () => interopDefault(import('../pages/pages/delivery-info.vue' /* webpackChunkName: "pages/pages/delivery-info" */))
const _26b3e218 = () => interopDefault(import('../pages/pages/discount-code.vue' /* webpackChunkName: "pages/pages/discount-code" */))
const _33478f5e = () => interopDefault(import('../pages/pages/faq.vue' /* webpackChunkName: "pages/pages/faq" */))
const _5f7ca495 = () => interopDefault(import('../pages/pages/join-us-on-tour.vue' /* webpackChunkName: "pages/pages/join-us-on-tour" */))
const _8107d82e = () => interopDefault(import('../pages/pages/klarna-frequently-asked-questions.vue' /* webpackChunkName: "pages/pages/klarna-frequently-asked-questions" */))
const _663e4e06 = () => interopDefault(import('../pages/pages/ladies.vue' /* webpackChunkName: "pages/pages/ladies" */))
const _e30ddf9c = () => interopDefault(import('../pages/pages/mens.vue' /* webpackChunkName: "pages/pages/mens" */))
const _2e3e68ac = () => interopDefault(import('../pages/pages/privacy-policy.vue' /* webpackChunkName: "pages/pages/privacy-policy" */))
const _0dd614ac = () => interopDefault(import('../pages/pages/returns-refunds.vue' /* webpackChunkName: "pages/pages/returns-refunds" */))
const _d5e9e554 = () => interopDefault(import('../pages/pages/reviews.vue' /* webpackChunkName: "pages/pages/reviews" */))
const _40b27ed1 = () => interopDefault(import('../pages/pages/rydale-gift-cards.vue' /* webpackChunkName: "pages/pages/rydale-gift-cards" */))
const _d158163e = () => interopDefault(import('../pages/pages/rydale-jobs.vue' /* webpackChunkName: "pages/pages/rydale-jobs" */))
const _c68ba2f2 = () => interopDefault(import('../pages/pages/sales.vue' /* webpackChunkName: "pages/pages/sales" */))
const _2d22e3f6 = () => interopDefault(import('../pages/pages/shows-events.vue' /* webpackChunkName: "pages/pages/shows-events" */))
const _96f62e20 = () => interopDefault(import('../pages/pages/sitemap.vue' /* webpackChunkName: "pages/pages/sitemap" */))
const _3d7f6e56 = () => interopDefault(import('../pages/pages/social-media-specialist.vue' /* webpackChunkName: "pages/pages/social-media-specialist" */))
const _54cb1b25 = () => interopDefault(import('../pages/pages/store-locator.vue' /* webpackChunkName: "pages/pages/store-locator" */))
const _5235733c = () => interopDefault(import('../pages/pages/sustainability.vue' /* webpackChunkName: "pages/pages/sustainability" */))
const _a5d9c4da = () => interopDefault(import('../pages/pages/terms-conditions.vue' /* webpackChunkName: "pages/pages/terms-conditions" */))
const _7f59b247 = () => interopDefault(import('../pages/wishlists/create.vue' /* webpackChunkName: "pages/wishlists/create" */))
const _0a5a9757 = () => interopDefault(import('../pages/account/addresses/new.vue' /* webpackChunkName: "pages/account/addresses/new" */))
const _a5d9e9c8 = () => interopDefault(import('../pages/auth/email/verify/index.vue' /* webpackChunkName: "pages/auth/email/verify/index" */))
const _5324f1ab = () => interopDefault(import('../pages/auth/password/reset/index.vue' /* webpackChunkName: "pages/auth/password/reset/index" */))
const _567e64dc = () => interopDefault(import('../pages/account/order/cancel/_id.vue' /* webpackChunkName: "pages/account/order/cancel/_id" */))
const _192f359a = () => interopDefault(import('../pages/account/social-accounts/unlink/_id.vue' /* webpackChunkName: "pages/account/social-accounts/unlink/_id" */))
const _12b67b1e = () => interopDefault(import('../pages/account/addresses/_id.vue' /* webpackChunkName: "pages/account/addresses/_id" */))
const _426099e6 = () => interopDefault(import('../pages/auth/callback/_slug.vue' /* webpackChunkName: "pages/auth/callback/_slug" */))
const _e7e4efc6 = () => interopDefault(import('../pages/account/order/_id.vue' /* webpackChunkName: "pages/account/order/_id" */))
const _1a1b2e14 = () => interopDefault(import('../pages/blogs/news/_slug.vue' /* webpackChunkName: "pages/blogs/news/_slug" */))
const _98579e62 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _684a411f = () => interopDefault(import('../pages/checkout/callback/_id.vue' /* webpackChunkName: "pages/checkout/callback/_id" */))
const _685d4209 = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _4fa75bce = () => interopDefault(import('../pages/collections/_slug/index.vue' /* webpackChunkName: "pages/collections/_slug/index" */))
const _6fdb1a05 = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _36cfaf6c = () => interopDefault(import('../pages/wishlists/_id/edit.vue' /* webpackChunkName: "pages/wishlists/_id/edit" */))
const _062db13f = () => interopDefault(import('../pages/collections/_slug/products/_prodslug.vue' /* webpackChunkName: "pages/collections/_slug/products/_prodslug" */))
const _23240d0c = () => interopDefault(import('../pages/wishlists/_id/_slug.vue' /* webpackChunkName: "pages/wishlists/_id/_slug" */))
const _2c447510 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _cbd4fa14,
    name: "account___en"
  }, {
    path: "/auth",
    component: _2161b8d6,
    name: "auth___en"
  }, {
    path: "/cart",
    component: _07b969e6,
    name: "cart___en"
  }, {
    path: "/collections",
    component: _4e64555e,
    name: "collections___en"
  }, {
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/de-at",
    component: _2dfb1658,
    name: "index___de-at"
  }, {
    path: "/de-be",
    component: _2dfb1658,
    name: "index___de-be"
  }, {
    path: "/en-be",
    component: _2dfb1658,
    name: "index___en-be"
  }, {
    path: "/en-ca",
    component: _2dfb1658,
    name: "index___en-ca"
  }, {
    path: "/en-ch",
    component: _2dfb1658,
    name: "index___en-ch"
  }, {
    path: "/en-de",
    component: _2dfb1658,
    name: "index___en-de"
  }, {
    path: "/en-dk",
    component: _2dfb1658,
    name: "index___en-dk"
  }, {
    path: "/en-es",
    component: _2dfb1658,
    name: "index___en-es"
  }, {
    path: "/en-fr",
    component: _2dfb1658,
    name: "index___en-fr"
  }, {
    path: "/en-it",
    component: _2dfb1658,
    name: "index___en-it"
  }, {
    path: "/en-nl",
    component: _2dfb1658,
    name: "index___en-nl"
  }, {
    path: "/en-se",
    component: _2dfb1658,
    name: "index___en-se"
  }, {
    path: "/es",
    component: _2dfb1658,
    name: "index___es"
  }, {
    path: "/fr",
    component: _2dfb1658,
    name: "index___fr"
  }, {
    path: "/fr-be",
    component: _2dfb1658,
    name: "index___fr-be"
  }, {
    path: "/ie",
    component: _2dfb1658,
    name: "index___ie"
  }, {
    path: "/products",
    component: _6a13e351,
    name: "products___en"
  }, {
    path: "/shopping-event",
    component: _15b911df,
    name: "shopping-event___en"
  }, {
    path: "/us",
    component: _2dfb1658,
    name: "index___us"
  }, {
    path: "/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en"
  }, {
    path: "/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en"
  }, {
    path: "/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en"
  }, {
    path: "/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___en"
  }, {
    path: "/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en"
  }, {
    path: "/auth/signup",
    component: _9b880836,
    name: "auth-signup___en"
  }, {
    path: "/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en"
  }, {
    path: "/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en"
  }, {
    path: "/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en"
  }, {
    path: "/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en"
  }, {
    path: "/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en"
  }, {
    path: "/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en"
  }, {
    path: "/de-at/auth",
    component: _2161b8d6,
    name: "auth___de-at"
  }, {
    path: "/de-at/benutzerkonto",
    component: _cbd4fa14,
    name: "account___de-at"
  }, {
    path: "/de-at/einkaufswagen",
    component: _07b969e6,
    name: "cart___de-at"
  }, {
    path: "/de-at/produkte",
    component: _6a13e351,
    name: "products___de-at"
  }, {
    path: "/de-at/sammlungen",
    component: _4e64555e,
    name: "collections___de-at"
  }, {
    path: "/de-at/shopping-event",
    component: _15b911df,
    name: "shopping-event___de-at"
  }, {
    path: "/de-be/auth",
    component: _2161b8d6,
    name: "auth___de-be"
  }, {
    path: "/de-be/benutzerkonto",
    component: _cbd4fa14,
    name: "account___de-be"
  }, {
    path: "/de-be/einkaufswagen",
    component: _07b969e6,
    name: "cart___de-be"
  }, {
    path: "/de-be/produkte",
    component: _6a13e351,
    name: "products___de-be"
  }, {
    path: "/de-be/sammlungen",
    component: _4e64555e,
    name: "collections___de-be"
  }, {
    path: "/de-be/shopping-event",
    component: _15b911df,
    name: "shopping-event___de-be"
  }, {
    path: "/de/auth",
    component: _2161b8d6,
    name: "auth___de"
  }, {
    path: "/de/benutzerkonto",
    component: _cbd4fa14,
    name: "account___de"
  }, {
    path: "/de/einkaufswagen",
    component: _07b969e6,
    name: "cart___de"
  }, {
    path: "/de/produkte",
    component: _6a13e351,
    name: "products___de"
  }, {
    path: "/de/sammlungen",
    component: _4e64555e,
    name: "collections___de"
  }, {
    path: "/de/shopping-event",
    component: _15b911df,
    name: "shopping-event___de"
  }, {
    path: "/en-be/account",
    component: _cbd4fa14,
    name: "account___en-be"
  }, {
    path: "/en-be/auth",
    component: _2161b8d6,
    name: "auth___en-be"
  }, {
    path: "/en-be/cart",
    component: _07b969e6,
    name: "cart___en-be"
  }, {
    path: "/en-be/collections",
    component: _4e64555e,
    name: "collections___en-be"
  }, {
    path: "/en-be/products",
    component: _6a13e351,
    name: "products___en-be"
  }, {
    path: "/en-be/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-be"
  }, {
    path: "/en-ca/account",
    component: _cbd4fa14,
    name: "account___en-ca"
  }, {
    path: "/en-ca/auth",
    component: _2161b8d6,
    name: "auth___en-ca"
  }, {
    path: "/en-ca/cart",
    component: _07b969e6,
    name: "cart___en-ca"
  }, {
    path: "/en-ca/collections",
    component: _4e64555e,
    name: "collections___en-ca"
  }, {
    path: "/en-ca/products",
    component: _6a13e351,
    name: "products___en-ca"
  }, {
    path: "/en-ca/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-ca"
  }, {
    path: "/en-ch/account",
    component: _cbd4fa14,
    name: "account___en-ch"
  }, {
    path: "/en-ch/auth",
    component: _2161b8d6,
    name: "auth___en-ch"
  }, {
    path: "/en-ch/cart",
    component: _07b969e6,
    name: "cart___en-ch"
  }, {
    path: "/en-ch/collections",
    component: _4e64555e,
    name: "collections___en-ch"
  }, {
    path: "/en-ch/products",
    component: _6a13e351,
    name: "products___en-ch"
  }, {
    path: "/en-ch/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-ch"
  }, {
    path: "/en-de/account",
    component: _cbd4fa14,
    name: "account___en-de"
  }, {
    path: "/en-de/auth",
    component: _2161b8d6,
    name: "auth___en-de"
  }, {
    path: "/en-de/cart",
    component: _07b969e6,
    name: "cart___en-de"
  }, {
    path: "/en-de/collections",
    component: _4e64555e,
    name: "collections___en-de"
  }, {
    path: "/en-de/products",
    component: _6a13e351,
    name: "products___en-de"
  }, {
    path: "/en-de/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-de"
  }, {
    path: "/en-dk/account",
    component: _cbd4fa14,
    name: "account___en-dk"
  }, {
    path: "/en-dk/auth",
    component: _2161b8d6,
    name: "auth___en-dk"
  }, {
    path: "/en-dk/cart",
    component: _07b969e6,
    name: "cart___en-dk"
  }, {
    path: "/en-dk/collections",
    component: _4e64555e,
    name: "collections___en-dk"
  }, {
    path: "/en-dk/products",
    component: _6a13e351,
    name: "products___en-dk"
  }, {
    path: "/en-dk/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-dk"
  }, {
    path: "/en-es/account",
    component: _cbd4fa14,
    name: "account___en-es"
  }, {
    path: "/en-es/auth",
    component: _2161b8d6,
    name: "auth___en-es"
  }, {
    path: "/en-es/cart",
    component: _07b969e6,
    name: "cart___en-es"
  }, {
    path: "/en-es/collections",
    component: _4e64555e,
    name: "collections___en-es"
  }, {
    path: "/en-es/products",
    component: _6a13e351,
    name: "products___en-es"
  }, {
    path: "/en-es/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-es"
  }, {
    path: "/en-fr/account",
    component: _cbd4fa14,
    name: "account___en-fr"
  }, {
    path: "/en-fr/auth",
    component: _2161b8d6,
    name: "auth___en-fr"
  }, {
    path: "/en-fr/cart",
    component: _07b969e6,
    name: "cart___en-fr"
  }, {
    path: "/en-fr/collections",
    component: _4e64555e,
    name: "collections___en-fr"
  }, {
    path: "/en-fr/products",
    component: _6a13e351,
    name: "products___en-fr"
  }, {
    path: "/en-fr/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-fr"
  }, {
    path: "/en-it/account",
    component: _cbd4fa14,
    name: "account___en-it"
  }, {
    path: "/en-it/auth",
    component: _2161b8d6,
    name: "auth___en-it"
  }, {
    path: "/en-it/cart",
    component: _07b969e6,
    name: "cart___en-it"
  }, {
    path: "/en-it/collections",
    component: _4e64555e,
    name: "collections___en-it"
  }, {
    path: "/en-it/products",
    component: _6a13e351,
    name: "products___en-it"
  }, {
    path: "/en-it/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-it"
  }, {
    path: "/en-nl/account",
    component: _cbd4fa14,
    name: "account___en-nl"
  }, {
    path: "/en-nl/auth",
    component: _2161b8d6,
    name: "auth___en-nl"
  }, {
    path: "/en-nl/cart",
    component: _07b969e6,
    name: "cart___en-nl"
  }, {
    path: "/en-nl/collections",
    component: _4e64555e,
    name: "collections___en-nl"
  }, {
    path: "/en-nl/products",
    component: _6a13e351,
    name: "products___en-nl"
  }, {
    path: "/en-nl/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-nl"
  }, {
    path: "/en-se/account",
    component: _cbd4fa14,
    name: "account___en-se"
  }, {
    path: "/en-se/auth",
    component: _2161b8d6,
    name: "auth___en-se"
  }, {
    path: "/en-se/cart",
    component: _07b969e6,
    name: "cart___en-se"
  }, {
    path: "/en-se/collections",
    component: _4e64555e,
    name: "collections___en-se"
  }, {
    path: "/en-se/products",
    component: _6a13e351,
    name: "products___en-se"
  }, {
    path: "/en-se/shopping-event",
    component: _15b911df,
    name: "shopping-event___en-se"
  }, {
    path: "/es/auth",
    component: _2161b8d6,
    name: "auth___es"
  }, {
    path: "/es/cesta",
    component: _07b969e6,
    name: "cart___es"
  }, {
    path: "/es/colleciones",
    component: _4e64555e,
    name: "collections___es"
  }, {
    path: "/es/cuenta",
    component: _cbd4fa14,
    name: "account___es"
  }, {
    path: "/es/productos",
    component: _6a13e351,
    name: "products___es"
  }, {
    path: "/es/shopping-event",
    component: _15b911df,
    name: "shopping-event___es"
  }, {
    path: "/fr-be/auth",
    component: _2161b8d6,
    name: "auth___fr-be"
  }, {
    path: "/fr-be/collections",
    component: _4e64555e,
    name: "collections___fr-be"
  }, {
    path: "/fr-be/compte",
    component: _cbd4fa14,
    name: "account___fr-be"
  }, {
    path: "/fr-be/panier",
    component: _07b969e6,
    name: "cart___fr-be"
  }, {
    path: "/fr-be/produits",
    component: _6a13e351,
    name: "products___fr-be"
  }, {
    path: "/fr-be/shopping-event",
    component: _15b911df,
    name: "shopping-event___fr-be"
  }, {
    path: "/fr/auth",
    component: _2161b8d6,
    name: "auth___fr"
  }, {
    path: "/fr/collections",
    component: _4e64555e,
    name: "collections___fr"
  }, {
    path: "/fr/compte",
    component: _cbd4fa14,
    name: "account___fr"
  }, {
    path: "/fr/panier",
    component: _07b969e6,
    name: "cart___fr"
  }, {
    path: "/fr/produits",
    component: _6a13e351,
    name: "products___fr"
  }, {
    path: "/fr/shopping-event",
    component: _15b911df,
    name: "shopping-event___fr"
  }, {
    path: "/ie/account",
    component: _cbd4fa14,
    name: "account___ie"
  }, {
    path: "/ie/auth",
    component: _2161b8d6,
    name: "auth___ie"
  }, {
    path: "/ie/cart",
    component: _07b969e6,
    name: "cart___ie"
  }, {
    path: "/ie/collections",
    component: _4e64555e,
    name: "collections___ie"
  }, {
    path: "/ie/products",
    component: _6a13e351,
    name: "products___ie"
  }, {
    path: "/ie/shopping-event",
    component: _15b911df,
    name: "shopping-event___ie"
  }, {
    path: "/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en"
  }, {
    path: "/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en"
  }, {
    path: "/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en"
  }, {
    path: "/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en"
  }, {
    path: "/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en"
  }, {
    path: "/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en"
  }, {
    path: "/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en"
  }, {
    path: "/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en"
  }, {
    path: "/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en"
  }, {
    path: "/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en"
  }, {
    path: "/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en"
  }, {
    path: "/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en"
  }, {
    path: "/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en"
  }, {
    path: "/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en"
  }, {
    path: "/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en"
  }, {
    path: "/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en"
  }, {
    path: "/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en"
  }, {
    path: "/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en"
  }, {
    path: "/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en"
  }, {
    path: "/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en"
  }, {
    path: "/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en"
  }, {
    path: "/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en"
  }, {
    path: "/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en"
  }, {
    path: "/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en"
  }, {
    path: "/us/account",
    component: _cbd4fa14,
    name: "account___us"
  }, {
    path: "/us/auth",
    component: _2161b8d6,
    name: "auth___us"
  }, {
    path: "/us/cart",
    component: _07b969e6,
    name: "cart___us"
  }, {
    path: "/us/collections",
    component: _4e64555e,
    name: "collections___us"
  }, {
    path: "/us/products",
    component: _6a13e351,
    name: "products___us"
  }, {
    path: "/us/shopping-event",
    component: _15b911df,
    name: "shopping-event___us"
  }, {
    path: "/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en"
  }, {
    path: "/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en"
  }, {
    path: "/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en"
  }, {
    path: "/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en"
  }, {
    path: "/de-at/auth/anmelden",
    component: _9b880836,
    name: "auth-signup___de-at"
  }, {
    path: "/de-at/auth/einloggen",
    component: _8e1272a8,
    name: "auth-login___de-at"
  }, {
    path: "/de-at/auth/validierung",
    component: _2f3a1846,
    name: "auth-validation___de-at"
  }, {
    path: "/de-at/auth/zuruecksetzen",
    component: _cfdcdcdc,
    name: "auth-reset___de-at"
  }, {
    path: "/de-at/benutzerkonto/adressen",
    component: _1066e1c9,
    name: "account-addresses___de-at"
  }, {
    path: "/de-at/benutzerkonto/email-aendern",
    component: _2efdd76a,
    name: "account-change-email___de-at"
  }, {
    path: "/de-at/benutzerkonto/passwort-aendern",
    component: _17bf4f88,
    name: "account-change-password___de-at"
  }, {
    path: "/de-at/benutzerkonto/profil-bearbeiten",
    component: _09092a72,
    name: "account-edit-profile___de-at"
  }, {
    path: "/de-at/blogs/neuigkeiten",
    component: _16adeb84,
    name: "blogs-news___de-at"
  }, {
    path: "/de-at/kasse/gast",
    component: _7413567c,
    name: "checkout-guest___de-at"
  }, {
    path: "/de-at/kasse/info",
    component: _5724d8dd,
    name: "checkout-info___de-at"
  }, {
    path: "/de-at/kasse/lieferung",
    component: _ba494cba,
    name: "checkout-delivery___de-at"
  }, {
    path: "/de-at/kasse/zahlung",
    component: _affa8a2c,
    name: "checkout-payment___de-at"
  }, {
    path: "/de-at/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___de-at"
  }, {
    path: "/de-at/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___de-at"
  }, {
    path: "/de-at/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___de-at"
  }, {
    path: "/de-at/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___de-at"
  }, {
    path: "/de-at/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___de-at"
  }, {
    path: "/de-at/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___de-at"
  }, {
    path: "/de-at/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___de-at"
  }, {
    path: "/de-at/seiten/ausverkauf",
    component: _c68ba2f2,
    name: "pages-sales___de-at"
  }, {
    path: "/de-at/seiten/damen",
    component: _663e4e06,
    name: "pages-ladies___de-at"
  }, {
    path: "/de-at/seiten/datenschutzrichtlinie",
    component: _2e3e68ac,
    name: "pages-privacy-policy___de-at"
  }, {
    path: "/de-at/seiten/faq",
    component: _33478f5e,
    name: "pages-faq___de-at"
  }, {
    path: "/de-at/seiten/filialsuche",
    component: _54cb1b25,
    name: "pages-store-locator___de-at"
  }, {
    path: "/de-at/seiten/geschaeftsbedingungen",
    component: _a5d9c4da,
    name: "pages-terms-conditions___de-at"
  }, {
    path: "/de-at/seiten/herren",
    component: _e30ddf9c,
    name: "pages-mens___de-at"
  }, {
    path: "/de-at/seiten/karrieren-bei-rydale",
    component: _d158163e,
    name: "pages-rydale-jobs___de-at"
  }, {
    path: "/de-at/seiten/kinder",
    component: _54638b62,
    name: "pages-childrens___de-at"
  }, {
    path: "/de-at/seiten/klarna-haeufig-gestellte-fragen",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___de-at"
  }, {
    path: "/de-at/seiten/kontaktieren-sie-uns",
    component: _591f2180,
    name: "pages-contact-us___de-at"
  }, {
    path: "/de-at/seiten/lieferinformation",
    component: _c334a03c,
    name: "pages-delivery-info___de-at"
  }, {
    path: "/de-at/seiten/ruecksendungen-rueckerstattungen",
    component: _0dd614ac,
    name: "pages-returns-refunds___de-at"
  }, {
    path: "/de-at/seiten/rydale-geschenkkarten",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___de-at"
  }, {
    path: "/de-at/seiten/shows-veranstaltungen",
    component: _2d22e3f6,
    name: "pages-shows-events___de-at"
  }, {
    path: "/de-at/seiten/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___de-at"
  }, {
    path: "/de-at/seiten/ueber-uns",
    component: _3a6d0e9a,
    name: "pages-about-us___de-at"
  }, {
    path: "/de-at/wunschliste/erstellen",
    component: _7f59b247,
    name: "wishlists-create___de-at"
  }, {
    path: "/de-be/auth/anmelden",
    component: _9b880836,
    name: "auth-signup___de-be"
  }, {
    path: "/de-be/auth/einloggen",
    component: _8e1272a8,
    name: "auth-login___de-be"
  }, {
    path: "/de-be/auth/validierung",
    component: _2f3a1846,
    name: "auth-validation___de-be"
  }, {
    path: "/de-be/auth/zuruecksetzen",
    component: _cfdcdcdc,
    name: "auth-reset___de-be"
  }, {
    path: "/de-be/benutzerkonto/adressen",
    component: _1066e1c9,
    name: "account-addresses___de-be"
  }, {
    path: "/de-be/benutzerkonto/email-aendern",
    component: _2efdd76a,
    name: "account-change-email___de-be"
  }, {
    path: "/de-be/benutzerkonto/passwort-aendern",
    component: _17bf4f88,
    name: "account-change-password___de-be"
  }, {
    path: "/de-be/benutzerkonto/profil-bearbeiten",
    component: _09092a72,
    name: "account-edit-profile___de-be"
  }, {
    path: "/de-be/blogs/neuigkeiten",
    component: _16adeb84,
    name: "blogs-news___de-be"
  }, {
    path: "/de-be/kasse/gast",
    component: _7413567c,
    name: "checkout-guest___de-be"
  }, {
    path: "/de-be/kasse/info",
    component: _5724d8dd,
    name: "checkout-info___de-be"
  }, {
    path: "/de-be/kasse/lieferung",
    component: _ba494cba,
    name: "checkout-delivery___de-be"
  }, {
    path: "/de-be/kasse/zahlung",
    component: _affa8a2c,
    name: "checkout-payment___de-be"
  }, {
    path: "/de-be/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___de-be"
  }, {
    path: "/de-be/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___de-be"
  }, {
    path: "/de-be/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___de-be"
  }, {
    path: "/de-be/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___de-be"
  }, {
    path: "/de-be/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___de-be"
  }, {
    path: "/de-be/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___de-be"
  }, {
    path: "/de-be/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___de-be"
  }, {
    path: "/de-be/seiten/ausverkauf",
    component: _c68ba2f2,
    name: "pages-sales___de-be"
  }, {
    path: "/de-be/seiten/damen",
    component: _663e4e06,
    name: "pages-ladies___de-be"
  }, {
    path: "/de-be/seiten/datenschutzrichtlinie",
    component: _2e3e68ac,
    name: "pages-privacy-policy___de-be"
  }, {
    path: "/de-be/seiten/faq",
    component: _33478f5e,
    name: "pages-faq___de-be"
  }, {
    path: "/de-be/seiten/filialsuche",
    component: _54cb1b25,
    name: "pages-store-locator___de-be"
  }, {
    path: "/de-be/seiten/geschaeftsbedingungen",
    component: _a5d9c4da,
    name: "pages-terms-conditions___de-be"
  }, {
    path: "/de-be/seiten/herren",
    component: _e30ddf9c,
    name: "pages-mens___de-be"
  }, {
    path: "/de-be/seiten/karrieren-bei-rydale",
    component: _d158163e,
    name: "pages-rydale-jobs___de-be"
  }, {
    path: "/de-be/seiten/kinder",
    component: _54638b62,
    name: "pages-childrens___de-be"
  }, {
    path: "/de-be/seiten/klarna-haeufig-gestellte-fragen",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___de-be"
  }, {
    path: "/de-be/seiten/kontaktieren-sie-uns",
    component: _591f2180,
    name: "pages-contact-us___de-be"
  }, {
    path: "/de-be/seiten/lieferinformation",
    component: _c334a03c,
    name: "pages-delivery-info___de-be"
  }, {
    path: "/de-be/seiten/ruecksendungen-rueckerstattungen",
    component: _0dd614ac,
    name: "pages-returns-refunds___de-be"
  }, {
    path: "/de-be/seiten/rydale-geschenkkarten",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___de-be"
  }, {
    path: "/de-be/seiten/shows-veranstaltungen",
    component: _2d22e3f6,
    name: "pages-shows-events___de-be"
  }, {
    path: "/de-be/seiten/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___de-be"
  }, {
    path: "/de-be/seiten/ueber-uns",
    component: _3a6d0e9a,
    name: "pages-about-us___de-be"
  }, {
    path: "/de-be/wunschliste/erstellen",
    component: _7f59b247,
    name: "wishlists-create___de-be"
  }, {
    path: "/de/auth/anmelden",
    component: _9b880836,
    name: "auth-signup___de"
  }, {
    path: "/de/auth/einloggen",
    component: _8e1272a8,
    name: "auth-login___de"
  }, {
    path: "/de/auth/validierung",
    component: _2f3a1846,
    name: "auth-validation___de"
  }, {
    path: "/de/auth/zuruecksetzen",
    component: _cfdcdcdc,
    name: "auth-reset___de"
  }, {
    path: "/de/benutzerkonto/adressen",
    component: _1066e1c9,
    name: "account-addresses___de"
  }, {
    path: "/de/benutzerkonto/email-aendern",
    component: _2efdd76a,
    name: "account-change-email___de"
  }, {
    path: "/de/benutzerkonto/passwort-aendern",
    component: _17bf4f88,
    name: "account-change-password___de"
  }, {
    path: "/de/benutzerkonto/profil-bearbeiten",
    component: _09092a72,
    name: "account-edit-profile___de"
  }, {
    path: "/de/blogs/neuigkeiten",
    component: _16adeb84,
    name: "blogs-news___de"
  }, {
    path: "/de/kasse/gast",
    component: _7413567c,
    name: "checkout-guest___de"
  }, {
    path: "/de/kasse/info",
    component: _5724d8dd,
    name: "checkout-info___de"
  }, {
    path: "/de/kasse/lieferung",
    component: _ba494cba,
    name: "checkout-delivery___de"
  }, {
    path: "/de/kasse/zahlung",
    component: _affa8a2c,
    name: "checkout-payment___de"
  }, {
    path: "/de/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___de"
  }, {
    path: "/de/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___de"
  }, {
    path: "/de/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___de"
  }, {
    path: "/de/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___de"
  }, {
    path: "/de/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___de"
  }, {
    path: "/de/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___de"
  }, {
    path: "/de/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___de"
  }, {
    path: "/de/seiten/ausverkauf",
    component: _c68ba2f2,
    name: "pages-sales___de"
  }, {
    path: "/de/seiten/damen",
    component: _663e4e06,
    name: "pages-ladies___de"
  }, {
    path: "/de/seiten/datenschutzrichtlinie",
    component: _2e3e68ac,
    name: "pages-privacy-policy___de"
  }, {
    path: "/de/seiten/faq",
    component: _33478f5e,
    name: "pages-faq___de"
  }, {
    path: "/de/seiten/filialsuche",
    component: _54cb1b25,
    name: "pages-store-locator___de"
  }, {
    path: "/de/seiten/geschaeftsbedingungen",
    component: _a5d9c4da,
    name: "pages-terms-conditions___de"
  }, {
    path: "/de/seiten/herren",
    component: _e30ddf9c,
    name: "pages-mens___de"
  }, {
    path: "/de/seiten/karrieren-bei-rydale",
    component: _d158163e,
    name: "pages-rydale-jobs___de"
  }, {
    path: "/de/seiten/kinder",
    component: _54638b62,
    name: "pages-childrens___de"
  }, {
    path: "/de/seiten/klarna-haeufig-gestellte-fragen",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___de"
  }, {
    path: "/de/seiten/kontaktieren-sie-uns",
    component: _591f2180,
    name: "pages-contact-us___de"
  }, {
    path: "/de/seiten/lieferinformation",
    component: _c334a03c,
    name: "pages-delivery-info___de"
  }, {
    path: "/de/seiten/ruecksendungen-rueckerstattungen",
    component: _0dd614ac,
    name: "pages-returns-refunds___de"
  }, {
    path: "/de/seiten/rydale-geschenkkarten",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___de"
  }, {
    path: "/de/seiten/shows-veranstaltungen",
    component: _2d22e3f6,
    name: "pages-shows-events___de"
  }, {
    path: "/de/seiten/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___de"
  }, {
    path: "/de/seiten/ueber-uns",
    component: _3a6d0e9a,
    name: "pages-about-us___de"
  }, {
    path: "/de/wunschliste/erstellen",
    component: _7f59b247,
    name: "wishlists-create___de"
  }, {
    path: "/en-be/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-be"
  }, {
    path: "/en-be/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-be"
  }, {
    path: "/en-be/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-be"
  }, {
    path: "/en-be/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-be"
  }, {
    path: "/en-be/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-be"
  }, {
    path: "/en-be/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-be"
  }, {
    path: "/en-be/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-be"
  }, {
    path: "/en-be/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-be"
  }, {
    path: "/en-be/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-be"
  }, {
    path: "/en-be/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-be"
  }, {
    path: "/en-be/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-be"
  }, {
    path: "/en-be/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-be"
  }, {
    path: "/en-be/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-be"
  }, {
    path: "/en-be/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-be"
  }, {
    path: "/en-be/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-be"
  }, {
    path: "/en-be/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-be"
  }, {
    path: "/en-be/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-be"
  }, {
    path: "/en-be/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-be"
  }, {
    path: "/en-be/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-be"
  }, {
    path: "/en-be/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-be"
  }, {
    path: "/en-be/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-be"
  }, {
    path: "/en-be/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-be"
  }, {
    path: "/en-be/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-be"
  }, {
    path: "/en-be/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-be"
  }, {
    path: "/en-be/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-be"
  }, {
    path: "/en-be/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-be"
  }, {
    path: "/en-be/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-be"
  }, {
    path: "/en-be/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-be"
  }, {
    path: "/en-be/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-be"
  }, {
    path: "/en-be/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-be"
  }, {
    path: "/en-be/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-be"
  }, {
    path: "/en-be/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-be"
  }, {
    path: "/en-be/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-be"
  }, {
    path: "/en-be/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-be"
  }, {
    path: "/en-be/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-be"
  }, {
    path: "/en-be/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-be"
  }, {
    path: "/en-be/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-be"
  }, {
    path: "/en-be/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-be"
  }, {
    path: "/en-ca/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-ca"
  }, {
    path: "/en-ca/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-ca"
  }, {
    path: "/en-ca/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-ca"
  }, {
    path: "/en-ca/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-ca"
  }, {
    path: "/en-ca/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-ca"
  }, {
    path: "/en-ca/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-ca"
  }, {
    path: "/en-ca/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-ca"
  }, {
    path: "/en-ca/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-ca"
  }, {
    path: "/en-ca/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-ca"
  }, {
    path: "/en-ca/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-ca"
  }, {
    path: "/en-ca/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-ca"
  }, {
    path: "/en-ca/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-ca"
  }, {
    path: "/en-ca/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-ca"
  }, {
    path: "/en-ca/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-ca"
  }, {
    path: "/en-ca/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-ca"
  }, {
    path: "/en-ca/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-ca"
  }, {
    path: "/en-ca/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-ca"
  }, {
    path: "/en-ca/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-ca"
  }, {
    path: "/en-ca/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-ca"
  }, {
    path: "/en-ca/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-ca"
  }, {
    path: "/en-ca/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-ca"
  }, {
    path: "/en-ca/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-ca"
  }, {
    path: "/en-ca/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-ca"
  }, {
    path: "/en-ca/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-ca"
  }, {
    path: "/en-ca/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-ca"
  }, {
    path: "/en-ca/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-ca"
  }, {
    path: "/en-ca/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-ca"
  }, {
    path: "/en-ca/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-ca"
  }, {
    path: "/en-ca/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-ca"
  }, {
    path: "/en-ca/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-ca"
  }, {
    path: "/en-ca/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-ca"
  }, {
    path: "/en-ca/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-ca"
  }, {
    path: "/en-ca/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-ca"
  }, {
    path: "/en-ca/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-ca"
  }, {
    path: "/en-ca/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-ca"
  }, {
    path: "/en-ca/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-ca"
  }, {
    path: "/en-ca/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-ca"
  }, {
    path: "/en-ca/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-ca"
  }, {
    path: "/en-ch/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-ch"
  }, {
    path: "/en-ch/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-ch"
  }, {
    path: "/en-ch/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-ch"
  }, {
    path: "/en-ch/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-ch"
  }, {
    path: "/en-ch/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-ch"
  }, {
    path: "/en-ch/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-ch"
  }, {
    path: "/en-ch/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-ch"
  }, {
    path: "/en-ch/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-ch"
  }, {
    path: "/en-ch/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-ch"
  }, {
    path: "/en-ch/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-ch"
  }, {
    path: "/en-ch/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-ch"
  }, {
    path: "/en-ch/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-ch"
  }, {
    path: "/en-ch/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-ch"
  }, {
    path: "/en-ch/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-ch"
  }, {
    path: "/en-ch/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-ch"
  }, {
    path: "/en-ch/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-ch"
  }, {
    path: "/en-ch/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-ch"
  }, {
    path: "/en-ch/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-ch"
  }, {
    path: "/en-ch/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-ch"
  }, {
    path: "/en-ch/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-ch"
  }, {
    path: "/en-ch/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-ch"
  }, {
    path: "/en-ch/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-ch"
  }, {
    path: "/en-ch/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-ch"
  }, {
    path: "/en-ch/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-ch"
  }, {
    path: "/en-ch/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-ch"
  }, {
    path: "/en-ch/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-ch"
  }, {
    path: "/en-ch/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-ch"
  }, {
    path: "/en-ch/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-ch"
  }, {
    path: "/en-ch/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-ch"
  }, {
    path: "/en-ch/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-ch"
  }, {
    path: "/en-ch/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-ch"
  }, {
    path: "/en-ch/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-ch"
  }, {
    path: "/en-ch/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-ch"
  }, {
    path: "/en-ch/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-ch"
  }, {
    path: "/en-ch/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-ch"
  }, {
    path: "/en-ch/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-ch"
  }, {
    path: "/en-ch/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-ch"
  }, {
    path: "/en-ch/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-ch"
  }, {
    path: "/en-de/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-de"
  }, {
    path: "/en-de/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-de"
  }, {
    path: "/en-de/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-de"
  }, {
    path: "/en-de/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-de"
  }, {
    path: "/en-de/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-de"
  }, {
    path: "/en-de/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-de"
  }, {
    path: "/en-de/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-de"
  }, {
    path: "/en-de/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-de"
  }, {
    path: "/en-de/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-de"
  }, {
    path: "/en-de/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-de"
  }, {
    path: "/en-de/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-de"
  }, {
    path: "/en-de/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-de"
  }, {
    path: "/en-de/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-de"
  }, {
    path: "/en-de/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-de"
  }, {
    path: "/en-de/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-de"
  }, {
    path: "/en-de/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-de"
  }, {
    path: "/en-de/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-de"
  }, {
    path: "/en-de/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-de"
  }, {
    path: "/en-de/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-de"
  }, {
    path: "/en-de/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-de"
  }, {
    path: "/en-de/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-de"
  }, {
    path: "/en-de/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-de"
  }, {
    path: "/en-de/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-de"
  }, {
    path: "/en-de/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-de"
  }, {
    path: "/en-de/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-de"
  }, {
    path: "/en-de/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-de"
  }, {
    path: "/en-de/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-de"
  }, {
    path: "/en-de/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-de"
  }, {
    path: "/en-de/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-de"
  }, {
    path: "/en-de/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-de"
  }, {
    path: "/en-de/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-de"
  }, {
    path: "/en-de/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-de"
  }, {
    path: "/en-de/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-de"
  }, {
    path: "/en-de/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-de"
  }, {
    path: "/en-de/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-de"
  }, {
    path: "/en-de/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-de"
  }, {
    path: "/en-de/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-de"
  }, {
    path: "/en-de/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-de"
  }, {
    path: "/en-dk/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-dk"
  }, {
    path: "/en-dk/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-dk"
  }, {
    path: "/en-dk/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-dk"
  }, {
    path: "/en-dk/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-dk"
  }, {
    path: "/en-dk/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-dk"
  }, {
    path: "/en-dk/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-dk"
  }, {
    path: "/en-dk/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-dk"
  }, {
    path: "/en-dk/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-dk"
  }, {
    path: "/en-dk/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-dk"
  }, {
    path: "/en-dk/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-dk"
  }, {
    path: "/en-dk/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-dk"
  }, {
    path: "/en-dk/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-dk"
  }, {
    path: "/en-dk/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-dk"
  }, {
    path: "/en-dk/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-dk"
  }, {
    path: "/en-dk/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-dk"
  }, {
    path: "/en-dk/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-dk"
  }, {
    path: "/en-dk/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-dk"
  }, {
    path: "/en-dk/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-dk"
  }, {
    path: "/en-dk/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-dk"
  }, {
    path: "/en-dk/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-dk"
  }, {
    path: "/en-dk/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-dk"
  }, {
    path: "/en-dk/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-dk"
  }, {
    path: "/en-dk/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-dk"
  }, {
    path: "/en-dk/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-dk"
  }, {
    path: "/en-dk/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-dk"
  }, {
    path: "/en-dk/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-dk"
  }, {
    path: "/en-dk/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-dk"
  }, {
    path: "/en-dk/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-dk"
  }, {
    path: "/en-dk/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-dk"
  }, {
    path: "/en-dk/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-dk"
  }, {
    path: "/en-dk/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-dk"
  }, {
    path: "/en-dk/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-dk"
  }, {
    path: "/en-dk/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-dk"
  }, {
    path: "/en-dk/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-dk"
  }, {
    path: "/en-dk/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-dk"
  }, {
    path: "/en-dk/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-dk"
  }, {
    path: "/en-dk/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-dk"
  }, {
    path: "/en-dk/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-dk"
  }, {
    path: "/en-es/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-es"
  }, {
    path: "/en-es/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-es"
  }, {
    path: "/en-es/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-es"
  }, {
    path: "/en-es/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-es"
  }, {
    path: "/en-es/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-es"
  }, {
    path: "/en-es/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-es"
  }, {
    path: "/en-es/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-es"
  }, {
    path: "/en-es/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-es"
  }, {
    path: "/en-es/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-es"
  }, {
    path: "/en-es/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-es"
  }, {
    path: "/en-es/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-es"
  }, {
    path: "/en-es/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-es"
  }, {
    path: "/en-es/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-es"
  }, {
    path: "/en-es/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-es"
  }, {
    path: "/en-es/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-es"
  }, {
    path: "/en-es/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-es"
  }, {
    path: "/en-es/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-es"
  }, {
    path: "/en-es/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-es"
  }, {
    path: "/en-es/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-es"
  }, {
    path: "/en-es/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-es"
  }, {
    path: "/en-es/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-es"
  }, {
    path: "/en-es/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-es"
  }, {
    path: "/en-es/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-es"
  }, {
    path: "/en-es/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-es"
  }, {
    path: "/en-es/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-es"
  }, {
    path: "/en-es/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-es"
  }, {
    path: "/en-es/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-es"
  }, {
    path: "/en-es/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-es"
  }, {
    path: "/en-es/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-es"
  }, {
    path: "/en-es/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-es"
  }, {
    path: "/en-es/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-es"
  }, {
    path: "/en-es/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-es"
  }, {
    path: "/en-es/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-es"
  }, {
    path: "/en-es/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-es"
  }, {
    path: "/en-es/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-es"
  }, {
    path: "/en-es/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-es"
  }, {
    path: "/en-es/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-es"
  }, {
    path: "/en-es/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-es"
  }, {
    path: "/en-fr/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-fr"
  }, {
    path: "/en-fr/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-fr"
  }, {
    path: "/en-fr/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-fr"
  }, {
    path: "/en-fr/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-fr"
  }, {
    path: "/en-fr/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-fr"
  }, {
    path: "/en-fr/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-fr"
  }, {
    path: "/en-fr/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-fr"
  }, {
    path: "/en-fr/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-fr"
  }, {
    path: "/en-fr/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-fr"
  }, {
    path: "/en-fr/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-fr"
  }, {
    path: "/en-fr/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-fr"
  }, {
    path: "/en-fr/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-fr"
  }, {
    path: "/en-fr/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-fr"
  }, {
    path: "/en-fr/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-fr"
  }, {
    path: "/en-fr/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-fr"
  }, {
    path: "/en-fr/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-fr"
  }, {
    path: "/en-fr/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-fr"
  }, {
    path: "/en-fr/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-fr"
  }, {
    path: "/en-fr/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-fr"
  }, {
    path: "/en-fr/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-fr"
  }, {
    path: "/en-fr/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-fr"
  }, {
    path: "/en-fr/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-fr"
  }, {
    path: "/en-fr/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-fr"
  }, {
    path: "/en-fr/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-fr"
  }, {
    path: "/en-fr/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-fr"
  }, {
    path: "/en-fr/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-fr"
  }, {
    path: "/en-fr/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-fr"
  }, {
    path: "/en-fr/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-fr"
  }, {
    path: "/en-fr/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-fr"
  }, {
    path: "/en-fr/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-fr"
  }, {
    path: "/en-fr/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-fr"
  }, {
    path: "/en-fr/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-fr"
  }, {
    path: "/en-fr/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-fr"
  }, {
    path: "/en-fr/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-fr"
  }, {
    path: "/en-fr/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-fr"
  }, {
    path: "/en-fr/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-fr"
  }, {
    path: "/en-fr/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-fr"
  }, {
    path: "/en-fr/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-fr"
  }, {
    path: "/en-it/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-it"
  }, {
    path: "/en-it/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-it"
  }, {
    path: "/en-it/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-it"
  }, {
    path: "/en-it/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-it"
  }, {
    path: "/en-it/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-it"
  }, {
    path: "/en-it/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-it"
  }, {
    path: "/en-it/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-it"
  }, {
    path: "/en-it/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-it"
  }, {
    path: "/en-it/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-it"
  }, {
    path: "/en-it/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-it"
  }, {
    path: "/en-it/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-it"
  }, {
    path: "/en-it/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-it"
  }, {
    path: "/en-it/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-it"
  }, {
    path: "/en-it/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-it"
  }, {
    path: "/en-it/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-it"
  }, {
    path: "/en-it/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-it"
  }, {
    path: "/en-it/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-it"
  }, {
    path: "/en-it/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-it"
  }, {
    path: "/en-it/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-it"
  }, {
    path: "/en-it/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-it"
  }, {
    path: "/en-it/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-it"
  }, {
    path: "/en-it/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-it"
  }, {
    path: "/en-it/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-it"
  }, {
    path: "/en-it/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-it"
  }, {
    path: "/en-it/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-it"
  }, {
    path: "/en-it/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-it"
  }, {
    path: "/en-it/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-it"
  }, {
    path: "/en-it/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-it"
  }, {
    path: "/en-it/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-it"
  }, {
    path: "/en-it/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-it"
  }, {
    path: "/en-it/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-it"
  }, {
    path: "/en-it/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-it"
  }, {
    path: "/en-it/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-it"
  }, {
    path: "/en-it/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-it"
  }, {
    path: "/en-it/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-it"
  }, {
    path: "/en-it/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-it"
  }, {
    path: "/en-it/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-it"
  }, {
    path: "/en-it/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-it"
  }, {
    path: "/en-nl/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-nl"
  }, {
    path: "/en-nl/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-nl"
  }, {
    path: "/en-nl/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-nl"
  }, {
    path: "/en-nl/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-nl"
  }, {
    path: "/en-nl/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-nl"
  }, {
    path: "/en-nl/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-nl"
  }, {
    path: "/en-nl/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-nl"
  }, {
    path: "/en-nl/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-nl"
  }, {
    path: "/en-nl/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-nl"
  }, {
    path: "/en-nl/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-nl"
  }, {
    path: "/en-nl/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-nl"
  }, {
    path: "/en-nl/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-nl"
  }, {
    path: "/en-nl/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-nl"
  }, {
    path: "/en-nl/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-nl"
  }, {
    path: "/en-nl/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-nl"
  }, {
    path: "/en-nl/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-nl"
  }, {
    path: "/en-nl/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-nl"
  }, {
    path: "/en-nl/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-nl"
  }, {
    path: "/en-nl/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-nl"
  }, {
    path: "/en-nl/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-nl"
  }, {
    path: "/en-nl/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-nl"
  }, {
    path: "/en-nl/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-nl"
  }, {
    path: "/en-nl/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-nl"
  }, {
    path: "/en-nl/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-nl"
  }, {
    path: "/en-nl/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-nl"
  }, {
    path: "/en-nl/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-nl"
  }, {
    path: "/en-nl/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-nl"
  }, {
    path: "/en-nl/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-nl"
  }, {
    path: "/en-nl/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-nl"
  }, {
    path: "/en-nl/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-nl"
  }, {
    path: "/en-nl/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-nl"
  }, {
    path: "/en-nl/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-nl"
  }, {
    path: "/en-nl/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-nl"
  }, {
    path: "/en-nl/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-nl"
  }, {
    path: "/en-nl/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-nl"
  }, {
    path: "/en-nl/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-nl"
  }, {
    path: "/en-nl/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-nl"
  }, {
    path: "/en-nl/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-nl"
  }, {
    path: "/en-se/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___en-se"
  }, {
    path: "/en-se/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___en-se"
  }, {
    path: "/en-se/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___en-se"
  }, {
    path: "/en-se/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___en-se"
  }, {
    path: "/en-se/auth/login",
    component: _8e1272a8,
    name: "auth-login___en-se"
  }, {
    path: "/en-se/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___en-se"
  }, {
    path: "/en-se/auth/signup",
    component: _9b880836,
    name: "auth-signup___en-se"
  }, {
    path: "/en-se/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___en-se"
  }, {
    path: "/en-se/blogs/news",
    component: _16adeb84,
    name: "blogs-news___en-se"
  }, {
    path: "/en-se/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___en-se"
  }, {
    path: "/en-se/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___en-se"
  }, {
    path: "/en-se/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___en-se"
  }, {
    path: "/en-se/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___en-se"
  }, {
    path: "/en-se/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___en-se"
  }, {
    path: "/en-se/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___en-se"
  }, {
    path: "/en-se/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___en-se"
  }, {
    path: "/en-se/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___en-se"
  }, {
    path: "/en-se/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___en-se"
  }, {
    path: "/en-se/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___en-se"
  }, {
    path: "/en-se/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___en-se"
  }, {
    path: "/en-se/pages/faq",
    component: _33478f5e,
    name: "pages-faq___en-se"
  }, {
    path: "/en-se/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___en-se"
  }, {
    path: "/en-se/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___en-se"
  }, {
    path: "/en-se/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___en-se"
  }, {
    path: "/en-se/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___en-se"
  }, {
    path: "/en-se/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___en-se"
  }, {
    path: "/en-se/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___en-se"
  }, {
    path: "/en-se/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___en-se"
  }, {
    path: "/en-se/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___en-se"
  }, {
    path: "/en-se/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___en-se"
  }, {
    path: "/en-se/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___en-se"
  }, {
    path: "/en-se/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___en-se"
  }, {
    path: "/en-se/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___en-se"
  }, {
    path: "/en-se/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___en-se"
  }, {
    path: "/en-se/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___en-se"
  }, {
    path: "/en-se/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___en-se"
  }, {
    path: "/en-se/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___en-se"
  }, {
    path: "/en-se/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___en-se"
  }, {
    path: "/es/auth/iniciar-session",
    component: _8e1272a8,
    name: "auth-login___es"
  }, {
    path: "/es/auth/registrarse",
    component: _9b880836,
    name: "auth-signup___es"
  }, {
    path: "/es/auth/restablecer",
    component: _cfdcdcdc,
    name: "auth-reset___es"
  }, {
    path: "/es/auth/validacion",
    component: _2f3a1846,
    name: "auth-validation___es"
  }, {
    path: "/es/blogs/novedades",
    component: _16adeb84,
    name: "blogs-news___es"
  }, {
    path: "/es/caja/envio",
    component: _ba494cba,
    name: "checkout-delivery___es"
  }, {
    path: "/es/caja/info",
    component: _5724d8dd,
    name: "checkout-info___es"
  }, {
    path: "/es/caja/invitado",
    component: _7413567c,
    name: "checkout-guest___es"
  }, {
    path: "/es/caja/pago",
    component: _affa8a2c,
    name: "checkout-payment___es"
  }, {
    path: "/es/cuenta/cambiar-contrasena",
    component: _17bf4f88,
    name: "account-change-password___es"
  }, {
    path: "/es/cuenta/cambiar-correo",
    component: _2efdd76a,
    name: "account-change-email___es"
  }, {
    path: "/es/cuenta/direcciones",
    component: _1066e1c9,
    name: "account-addresses___es"
  }, {
    path: "/es/cuenta/editar-perfil",
    component: _09092a72,
    name: "account-edit-profile___es"
  }, {
    path: "/es/favoritos/crear",
    component: _7f59b247,
    name: "wishlists-create___es"
  }, {
    path: "/es/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___es"
  }, {
    path: "/es/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___es"
  }, {
    path: "/es/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___es"
  }, {
    path: "/es/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___es"
  }, {
    path: "/es/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___es"
  }, {
    path: "/es/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___es"
  }, {
    path: "/es/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___es"
  }, {
    path: "/es/paginas/carreras-con-rydale",
    component: _d158163e,
    name: "pages-rydale-jobs___es"
  }, {
    path: "/es/paginas/condiciones-de-uso",
    component: _a5d9c4da,
    name: "pages-terms-conditions___es"
  }, {
    path: "/es/paginas/contactanos",
    component: _591f2180,
    name: "pages-contact-us___es"
  }, {
    path: "/es/paginas/devoluciones-reembolsos",
    component: _0dd614ac,
    name: "pages-returns-refunds___es"
  }, {
    path: "/es/paginas/espectaculos-eventos",
    component: _2d22e3f6,
    name: "pages-shows-events___es"
  }, {
    path: "/es/paginas/hombre",
    component: _e30ddf9c,
    name: "pages-mens___es"
  }, {
    path: "/es/paginas/info-de-envios",
    component: _c334a03c,
    name: "pages-delivery-info___es"
  }, {
    path: "/es/paginas/klarna-preguntas-frecuentes",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___es"
  }, {
    path: "/es/paginas/localizador-de-tiendas",
    component: _54cb1b25,
    name: "pages-store-locator___es"
  }, {
    path: "/es/paginas/mapa-del-sitio",
    component: _96f62e20,
    name: "pages-sitemap___es"
  }, {
    path: "/es/paginas/mujer",
    component: _663e4e06,
    name: "pages-ladies___es"
  }, {
    path: "/es/paginas/ninos",
    component: _54638b62,
    name: "pages-childrens___es"
  }, {
    path: "/es/paginas/politica-de-privacidad",
    component: _2e3e68ac,
    name: "pages-privacy-policy___es"
  }, {
    path: "/es/paginas/preguntas-frecuentes",
    component: _33478f5e,
    name: "pages-faq___es"
  }, {
    path: "/es/paginas/promociones",
    component: _c68ba2f2,
    name: "pages-sales___es"
  }, {
    path: "/es/paginas/quienes-somos",
    component: _3a6d0e9a,
    name: "pages-about-us___es"
  }, {
    path: "/es/paginas/tarjeta-regalo-rydale",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___es"
  }, {
    path: "/fr-be/auth/reinitialiser",
    component: _cfdcdcdc,
    name: "auth-reset___fr-be"
  }, {
    path: "/fr-be/auth/se-connecter",
    component: _8e1272a8,
    name: "auth-login___fr-be"
  }, {
    path: "/fr-be/auth/sinscrire",
    component: _9b880836,
    name: "auth-signup___fr-be"
  }, {
    path: "/fr-be/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___fr-be"
  }, {
    path: "/fr-be/blogs/nouveaute",
    component: _16adeb84,
    name: "blogs-news___fr-be"
  }, {
    path: "/fr-be/caisse/info",
    component: _5724d8dd,
    name: "checkout-info___fr-be"
  }, {
    path: "/fr-be/caisse/invite",
    component: _7413567c,
    name: "checkout-guest___fr-be"
  }, {
    path: "/fr-be/caisse/livraison",
    component: _ba494cba,
    name: "checkout-delivery___fr-be"
  }, {
    path: "/fr-be/caisse/paiement",
    component: _affa8a2c,
    name: "checkout-payment___fr-be"
  }, {
    path: "/fr-be/compte/adresses",
    component: _1066e1c9,
    name: "account-addresses___fr-be"
  }, {
    path: "/fr-be/compte/changer-email",
    component: _2efdd76a,
    name: "account-change-email___fr-be"
  }, {
    path: "/fr-be/compte/changer-mot-de-passe",
    component: _17bf4f88,
    name: "account-change-password___fr-be"
  }, {
    path: "/fr-be/compte/modifier-profil",
    component: _09092a72,
    name: "account-edit-profile___fr-be"
  }, {
    path: "/fr-be/favoris/creer",
    component: _7f59b247,
    name: "wishlists-create___fr-be"
  }, {
    path: "/fr-be/pages/a-propos-de-nous",
    component: _3a6d0e9a,
    name: "pages-about-us___fr-be"
  }, {
    path: "/fr-be/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___fr-be"
  }, {
    path: "/fr-be/pages/carrieres-chez-rydale",
    component: _d158163e,
    name: "pages-rydale-jobs___fr-be"
  }, {
    path: "/fr-be/pages/cartes-cadeaux-rydale",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___fr-be"
  }, {
    path: "/fr-be/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___fr-be"
  }, {
    path: "/fr-be/pages/conditions-d-utilisation",
    component: _a5d9c4da,
    name: "pages-terms-conditions___fr-be"
  }, {
    path: "/fr-be/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___fr-be"
  }, {
    path: "/fr-be/pages/enfants",
    component: _54638b62,
    name: "pages-childrens___fr-be"
  }, {
    path: "/fr-be/pages/faq",
    component: _33478f5e,
    name: "pages-faq___fr-be"
  }, {
    path: "/fr-be/pages/femme",
    component: _663e4e06,
    name: "pages-ladies___fr-be"
  }, {
    path: "/fr-be/pages/homme",
    component: _e30ddf9c,
    name: "pages-mens___fr-be"
  }, {
    path: "/fr-be/pages/info-livraison",
    component: _c334a03c,
    name: "pages-delivery-info___fr-be"
  }, {
    path: "/fr-be/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___fr-be"
  }, {
    path: "/fr-be/pages/klarna-foire-aux-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___fr-be"
  }, {
    path: "/fr-be/pages/nous-contacter",
    component: _591f2180,
    name: "pages-contact-us___fr-be"
  }, {
    path: "/fr-be/pages/plan-du-site",
    component: _96f62e20,
    name: "pages-sitemap___fr-be"
  }, {
    path: "/fr-be/pages/politique-de-confidentialite",
    component: _2e3e68ac,
    name: "pages-privacy-policy___fr-be"
  }, {
    path: "/fr-be/pages/promotions",
    component: _c68ba2f2,
    name: "pages-sales___fr-be"
  }, {
    path: "/fr-be/pages/retours-remboursements",
    component: _0dd614ac,
    name: "pages-returns-refunds___fr-be"
  }, {
    path: "/fr-be/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___fr-be"
  }, {
    path: "/fr-be/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___fr-be"
  }, {
    path: "/fr-be/pages/spectacles-evenements",
    component: _2d22e3f6,
    name: "pages-shows-events___fr-be"
  }, {
    path: "/fr-be/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___fr-be"
  }, {
    path: "/fr-be/pages/trouver-un-revendeur",
    component: _54cb1b25,
    name: "pages-store-locator___fr-be"
  }, {
    path: "/fr/auth/reinitialiser",
    component: _cfdcdcdc,
    name: "auth-reset___fr"
  }, {
    path: "/fr/auth/se-connecter",
    component: _8e1272a8,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/sinscrire",
    component: _9b880836,
    name: "auth-signup___fr"
  }, {
    path: "/fr/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___fr"
  }, {
    path: "/fr/blogs/nouveaute",
    component: _16adeb84,
    name: "blogs-news___fr"
  }, {
    path: "/fr/caisse/info",
    component: _5724d8dd,
    name: "checkout-info___fr"
  }, {
    path: "/fr/caisse/invite",
    component: _7413567c,
    name: "checkout-guest___fr"
  }, {
    path: "/fr/caisse/livraison",
    component: _ba494cba,
    name: "checkout-delivery___fr"
  }, {
    path: "/fr/caisse/paiement",
    component: _affa8a2c,
    name: "checkout-payment___fr"
  }, {
    path: "/fr/compte/adresses",
    component: _1066e1c9,
    name: "account-addresses___fr"
  }, {
    path: "/fr/compte/changer-email",
    component: _2efdd76a,
    name: "account-change-email___fr"
  }, {
    path: "/fr/compte/changer-mot-de-passe",
    component: _17bf4f88,
    name: "account-change-password___fr"
  }, {
    path: "/fr/compte/modifier-profil",
    component: _09092a72,
    name: "account-edit-profile___fr"
  }, {
    path: "/fr/favoris/creer",
    component: _7f59b247,
    name: "wishlists-create___fr"
  }, {
    path: "/fr/pages/a-propos-de-nous",
    component: _3a6d0e9a,
    name: "pages-about-us___fr"
  }, {
    path: "/fr/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___fr"
  }, {
    path: "/fr/pages/carrieres-chez-rydale",
    component: _d158163e,
    name: "pages-rydale-jobs___fr"
  }, {
    path: "/fr/pages/cartes-cadeaux-rydale",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___fr"
  }, {
    path: "/fr/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___fr"
  }, {
    path: "/fr/pages/conditions-d-utilisation",
    component: _a5d9c4da,
    name: "pages-terms-conditions___fr"
  }, {
    path: "/fr/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___fr"
  }, {
    path: "/fr/pages/enfants",
    component: _54638b62,
    name: "pages-childrens___fr"
  }, {
    path: "/fr/pages/faq",
    component: _33478f5e,
    name: "pages-faq___fr"
  }, {
    path: "/fr/pages/femme",
    component: _663e4e06,
    name: "pages-ladies___fr"
  }, {
    path: "/fr/pages/homme",
    component: _e30ddf9c,
    name: "pages-mens___fr"
  }, {
    path: "/fr/pages/info-livraison",
    component: _c334a03c,
    name: "pages-delivery-info___fr"
  }, {
    path: "/fr/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___fr"
  }, {
    path: "/fr/pages/klarna-foire-aux-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___fr"
  }, {
    path: "/fr/pages/nous-contacter",
    component: _591f2180,
    name: "pages-contact-us___fr"
  }, {
    path: "/fr/pages/plan-du-site",
    component: _96f62e20,
    name: "pages-sitemap___fr"
  }, {
    path: "/fr/pages/politique-de-confidentialite",
    component: _2e3e68ac,
    name: "pages-privacy-policy___fr"
  }, {
    path: "/fr/pages/promotions",
    component: _c68ba2f2,
    name: "pages-sales___fr"
  }, {
    path: "/fr/pages/retours-remboursements",
    component: _0dd614ac,
    name: "pages-returns-refunds___fr"
  }, {
    path: "/fr/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___fr"
  }, {
    path: "/fr/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___fr"
  }, {
    path: "/fr/pages/spectacles-evenements",
    component: _2d22e3f6,
    name: "pages-shows-events___fr"
  }, {
    path: "/fr/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___fr"
  }, {
    path: "/fr/pages/trouver-un-revendeur",
    component: _54cb1b25,
    name: "pages-store-locator___fr"
  }, {
    path: "/ie/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___ie"
  }, {
    path: "/ie/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___ie"
  }, {
    path: "/ie/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___ie"
  }, {
    path: "/ie/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___ie"
  }, {
    path: "/ie/auth/login",
    component: _8e1272a8,
    name: "auth-login___ie"
  }, {
    path: "/ie/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___ie"
  }, {
    path: "/ie/auth/signup",
    component: _9b880836,
    name: "auth-signup___ie"
  }, {
    path: "/ie/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___ie"
  }, {
    path: "/ie/blogs/news",
    component: _16adeb84,
    name: "blogs-news___ie"
  }, {
    path: "/ie/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___ie"
  }, {
    path: "/ie/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___ie"
  }, {
    path: "/ie/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___ie"
  }, {
    path: "/ie/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___ie"
  }, {
    path: "/ie/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___ie"
  }, {
    path: "/ie/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___ie"
  }, {
    path: "/ie/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___ie"
  }, {
    path: "/ie/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___ie"
  }, {
    path: "/ie/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___ie"
  }, {
    path: "/ie/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___ie"
  }, {
    path: "/ie/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___ie"
  }, {
    path: "/ie/pages/faq",
    component: _33478f5e,
    name: "pages-faq___ie"
  }, {
    path: "/ie/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___ie"
  }, {
    path: "/ie/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___ie"
  }, {
    path: "/ie/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___ie"
  }, {
    path: "/ie/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___ie"
  }, {
    path: "/ie/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___ie"
  }, {
    path: "/ie/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___ie"
  }, {
    path: "/ie/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___ie"
  }, {
    path: "/ie/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___ie"
  }, {
    path: "/ie/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___ie"
  }, {
    path: "/ie/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___ie"
  }, {
    path: "/ie/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___ie"
  }, {
    path: "/ie/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___ie"
  }, {
    path: "/ie/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___ie"
  }, {
    path: "/ie/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___ie"
  }, {
    path: "/ie/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___ie"
  }, {
    path: "/ie/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___ie"
  }, {
    path: "/ie/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___ie"
  }, {
    path: "/us/account/addresses",
    component: _1066e1c9,
    name: "account-addresses___us"
  }, {
    path: "/us/account/change-email",
    component: _2efdd76a,
    name: "account-change-email___us"
  }, {
    path: "/us/account/change-password",
    component: _17bf4f88,
    name: "account-change-password___us"
  }, {
    path: "/us/account/edit-profile",
    component: _09092a72,
    name: "account-edit-profile___us"
  }, {
    path: "/us/auth/login",
    component: _8e1272a8,
    name: "auth-login___us"
  }, {
    path: "/us/auth/reset",
    component: _cfdcdcdc,
    name: "auth-reset___us"
  }, {
    path: "/us/auth/signup",
    component: _9b880836,
    name: "auth-signup___us"
  }, {
    path: "/us/auth/validation",
    component: _2f3a1846,
    name: "auth-validation___us"
  }, {
    path: "/us/blogs/news",
    component: _16adeb84,
    name: "blogs-news___us"
  }, {
    path: "/us/checkout/delivery",
    component: _ba494cba,
    name: "checkout-delivery___us"
  }, {
    path: "/us/checkout/guest",
    component: _7413567c,
    name: "checkout-guest___us"
  }, {
    path: "/us/checkout/info",
    component: _5724d8dd,
    name: "checkout-info___us"
  }, {
    path: "/us/checkout/payment",
    component: _affa8a2c,
    name: "checkout-payment___us"
  }, {
    path: "/us/pages/about-us",
    component: _3a6d0e9a,
    name: "pages-about-us___us"
  }, {
    path: "/us/pages/ambassador",
    component: _5df69be6,
    name: "pages-ambassador___us"
  }, {
    path: "/us/pages/childrens",
    component: _54638b62,
    name: "pages-childrens___us"
  }, {
    path: "/us/pages/choose-country-region",
    component: _7254ef08,
    name: "pages-choose-country-region___us"
  }, {
    path: "/us/pages/contact-us",
    component: _591f2180,
    name: "pages-contact-us___us"
  }, {
    path: "/us/pages/delivery-info",
    component: _c334a03c,
    name: "pages-delivery-info___us"
  }, {
    path: "/us/pages/discount-code",
    component: _26b3e218,
    name: "pages-discount-code___us"
  }, {
    path: "/us/pages/faq",
    component: _33478f5e,
    name: "pages-faq___us"
  }, {
    path: "/us/pages/join-us-on-tour",
    component: _5f7ca495,
    name: "pages-join-us-on-tour___us"
  }, {
    path: "/us/pages/klarna-frequently-asked-questions",
    component: _8107d82e,
    name: "pages-klarna-frequently-asked-questions___us"
  }, {
    path: "/us/pages/ladies",
    component: _663e4e06,
    name: "pages-ladies___us"
  }, {
    path: "/us/pages/mens",
    component: _e30ddf9c,
    name: "pages-mens___us"
  }, {
    path: "/us/pages/privacy-policy",
    component: _2e3e68ac,
    name: "pages-privacy-policy___us"
  }, {
    path: "/us/pages/returns-refunds",
    component: _0dd614ac,
    name: "pages-returns-refunds___us"
  }, {
    path: "/us/pages/reviews",
    component: _d5e9e554,
    name: "pages-reviews___us"
  }, {
    path: "/us/pages/rydale-gift-cards",
    component: _40b27ed1,
    name: "pages-rydale-gift-cards___us"
  }, {
    path: "/us/pages/rydale-jobs",
    component: _d158163e,
    name: "pages-rydale-jobs___us"
  }, {
    path: "/us/pages/sales",
    component: _c68ba2f2,
    name: "pages-sales___us"
  }, {
    path: "/us/pages/shows-events",
    component: _2d22e3f6,
    name: "pages-shows-events___us"
  }, {
    path: "/us/pages/sitemap",
    component: _96f62e20,
    name: "pages-sitemap___us"
  }, {
    path: "/us/pages/social-media-specialist",
    component: _3d7f6e56,
    name: "pages-social-media-specialist___us"
  }, {
    path: "/us/pages/store-locator",
    component: _54cb1b25,
    name: "pages-store-locator___us"
  }, {
    path: "/us/pages/sustainability",
    component: _5235733c,
    name: "pages-sustainability___us"
  }, {
    path: "/us/pages/terms-conditions",
    component: _a5d9c4da,
    name: "pages-terms-conditions___us"
  }, {
    path: "/us/wishlists/create",
    component: _7f59b247,
    name: "wishlists-create___us"
  }, {
    path: "/de-at/auth/email/verifizieren",
    component: _a5d9e9c8,
    name: "auth-email-verify___de-at"
  }, {
    path: "/de-at/auth/passwort/zuruecksetzen",
    component: _5324f1ab,
    name: "auth-password-reset___de-at"
  }, {
    path: "/de-at/benutzerkonto/adressen/neu",
    component: _0a5a9757,
    name: "account-addresses-new___de-at"
  }, {
    path: "/de-be/auth/email/verifizieren",
    component: _a5d9e9c8,
    name: "auth-email-verify___de-be"
  }, {
    path: "/de-be/auth/passwort/zuruecksetzen",
    component: _5324f1ab,
    name: "auth-password-reset___de-be"
  }, {
    path: "/de-be/benutzerkonto/adressen/neu",
    component: _0a5a9757,
    name: "account-addresses-new___de-be"
  }, {
    path: "/de/auth/email/verifizieren",
    component: _a5d9e9c8,
    name: "auth-email-verify___de"
  }, {
    path: "/de/auth/passwort/zuruecksetzen",
    component: _5324f1ab,
    name: "auth-password-reset___de"
  }, {
    path: "/de/benutzerkonto/adressen/neu",
    component: _0a5a9757,
    name: "account-addresses-new___de"
  }, {
    path: "/en-be/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-be"
  }, {
    path: "/en-be/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-be"
  }, {
    path: "/en-be/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-be"
  }, {
    path: "/en-ca/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-ca"
  }, {
    path: "/en-ca/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-ca"
  }, {
    path: "/en-ca/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-ca"
  }, {
    path: "/en-ch/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-ch"
  }, {
    path: "/en-ch/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-ch"
  }, {
    path: "/en-ch/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-ch"
  }, {
    path: "/en-de/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-de"
  }, {
    path: "/en-de/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-de"
  }, {
    path: "/en-de/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-de"
  }, {
    path: "/en-dk/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-dk"
  }, {
    path: "/en-dk/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-dk"
  }, {
    path: "/en-dk/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-dk"
  }, {
    path: "/en-es/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-es"
  }, {
    path: "/en-es/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-es"
  }, {
    path: "/en-es/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-es"
  }, {
    path: "/en-fr/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-fr"
  }, {
    path: "/en-fr/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-fr"
  }, {
    path: "/en-fr/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-fr"
  }, {
    path: "/en-it/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-it"
  }, {
    path: "/en-it/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-it"
  }, {
    path: "/en-it/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-it"
  }, {
    path: "/en-nl/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-nl"
  }, {
    path: "/en-nl/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-nl"
  }, {
    path: "/en-nl/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-nl"
  }, {
    path: "/en-se/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___en-se"
  }, {
    path: "/en-se/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___en-se"
  }, {
    path: "/en-se/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___en-se"
  }, {
    path: "/es/auth/contrasena/restablecer",
    component: _5324f1ab,
    name: "auth-password-reset___es"
  }, {
    path: "/es/auth/correo/verificar",
    component: _a5d9e9c8,
    name: "auth-email-verify___es"
  }, {
    path: "/es/cuenta/direcciones/nuevo",
    component: _0a5a9757,
    name: "account-addresses-new___es"
  }, {
    path: "/fr-be/auth/email/verifier",
    component: _a5d9e9c8,
    name: "auth-email-verify___fr-be"
  }, {
    path: "/fr-be/auth/mot-de-passe/renitialiser",
    component: _5324f1ab,
    name: "auth-password-reset___fr-be"
  }, {
    path: "/fr-be/compte/adresses/nouveau",
    component: _0a5a9757,
    name: "account-addresses-new___fr-be"
  }, {
    path: "/fr/auth/email/verifier",
    component: _a5d9e9c8,
    name: "auth-email-verify___fr"
  }, {
    path: "/fr/auth/mot-de-passe/renitialiser",
    component: _5324f1ab,
    name: "auth-password-reset___fr"
  }, {
    path: "/fr/compte/adresses/nouveau",
    component: _0a5a9757,
    name: "account-addresses-new___fr"
  }, {
    path: "/ie/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___ie"
  }, {
    path: "/ie/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___ie"
  }, {
    path: "/ie/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___ie"
  }, {
    path: "/us/account/addresses/new",
    component: _0a5a9757,
    name: "account-addresses-new___us"
  }, {
    path: "/us/auth/email/verify",
    component: _a5d9e9c8,
    name: "auth-email-verify___us"
  }, {
    path: "/us/auth/password/reset",
    component: _5324f1ab,
    name: "auth-password-reset___us"
  }, {
    path: "/de-at/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___de-at"
  }, {
    path: "/de-at/benutzerkonto/sozial-konten/aufheben/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___de-at"
  }, {
    path: "/de-be/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___de-be"
  }, {
    path: "/de-be/benutzerkonto/sozial-konten/aufheben/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___de-be"
  }, {
    path: "/de/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___de"
  }, {
    path: "/de/benutzerkonto/sozial-konten/aufheben/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___de"
  }, {
    path: "/en-be/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-be"
  }, {
    path: "/en-be/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-be"
  }, {
    path: "/en-ca/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-ca"
  }, {
    path: "/en-ca/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-ca"
  }, {
    path: "/en-ch/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-ch"
  }, {
    path: "/en-ch/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-ch"
  }, {
    path: "/en-de/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-de"
  }, {
    path: "/en-de/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-de"
  }, {
    path: "/en-dk/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-dk"
  }, {
    path: "/en-dk/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-dk"
  }, {
    path: "/en-es/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-es"
  }, {
    path: "/en-es/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-es"
  }, {
    path: "/en-fr/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-fr"
  }, {
    path: "/en-fr/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-fr"
  }, {
    path: "/en-it/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-it"
  }, {
    path: "/en-it/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-it"
  }, {
    path: "/en-nl/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-nl"
  }, {
    path: "/en-nl/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-nl"
  }, {
    path: "/en-se/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en-se"
  }, {
    path: "/en-se/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en-se"
  }, {
    path: "/es/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___es"
  }, {
    path: "/es/cuenta/direcciones/desvincular/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___es"
  }, {
    path: "/fr-be/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___fr-be"
  }, {
    path: "/fr-be/compte/comptes-sociaux/dissocier/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___fr-be"
  }, {
    path: "/fr/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___fr"
  }, {
    path: "/fr/compte/comptes-sociaux/dissocier/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___fr"
  }, {
    path: "/ie/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___ie"
  }, {
    path: "/ie/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___ie"
  }, {
    path: "/us/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___us"
  }, {
    path: "/us/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___us"
  }, {
    path: "/account/order/cancel/:id?",
    component: _567e64dc,
    name: "account-order-cancel-id___en"
  }, {
    path: "/account/social-accounts/unlink/:id",
    component: _192f359a,
    name: "account-social-accounts-unlink-id___en"
  }, {
    path: "/de-at/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___de-at"
  }, {
    path: "/de-at/auth/rueckruf/:slug",
    component: _426099e6,
    name: "auth-callback-slug___de-at"
  }, {
    path: "/de-at/benutzerkonto/bestellung/:id",
    component: _e7e4efc6,
    name: "account-order-id___de-at"
  }, {
    path: "/de-at/blogs/neuigkeiten/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___de-at"
  }, {
    path: "/de-at/kasse/erfolg/:id",
    component: _98579e62,
    name: "checkout-success-id___de-at"
  }, {
    path: "/de-at/kasse/rueckruf/:id",
    component: _684a411f,
    name: "checkout-callback-id___de-at"
  }, {
    path: "/de-be/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___de-be"
  }, {
    path: "/de-be/auth/rueckruf/:slug",
    component: _426099e6,
    name: "auth-callback-slug___de-be"
  }, {
    path: "/de-be/benutzerkonto/bestellung/:id",
    component: _e7e4efc6,
    name: "account-order-id___de-be"
  }, {
    path: "/de-be/blogs/neuigkeiten/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___de-be"
  }, {
    path: "/de-be/kasse/erfolg/:id",
    component: _98579e62,
    name: "checkout-success-id___de-be"
  }, {
    path: "/de-be/kasse/rueckruf/:id",
    component: _684a411f,
    name: "checkout-callback-id___de-be"
  }, {
    path: "/de/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___de"
  }, {
    path: "/de/auth/rueckruf/:slug",
    component: _426099e6,
    name: "auth-callback-slug___de"
  }, {
    path: "/de/benutzerkonto/bestellung/:id",
    component: _e7e4efc6,
    name: "account-order-id___de"
  }, {
    path: "/de/blogs/neuigkeiten/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___de"
  }, {
    path: "/de/kasse/erfolg/:id",
    component: _98579e62,
    name: "checkout-success-id___de"
  }, {
    path: "/de/kasse/rueckruf/:id",
    component: _684a411f,
    name: "checkout-callback-id___de"
  }, {
    path: "/en-be/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-be"
  }, {
    path: "/en-be/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-be"
  }, {
    path: "/en-be/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-be"
  }, {
    path: "/en-be/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-be"
  }, {
    path: "/en-be/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-be"
  }, {
    path: "/en-be/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-be"
  }, {
    path: "/en-ca/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-ca"
  }, {
    path: "/en-ca/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-ca"
  }, {
    path: "/en-ca/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-ca"
  }, {
    path: "/en-ca/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-ca"
  }, {
    path: "/en-ca/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-ca"
  }, {
    path: "/en-ca/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-ca"
  }, {
    path: "/en-ch/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-ch"
  }, {
    path: "/en-ch/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-ch"
  }, {
    path: "/en-ch/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-ch"
  }, {
    path: "/en-ch/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-ch"
  }, {
    path: "/en-ch/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-ch"
  }, {
    path: "/en-ch/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-ch"
  }, {
    path: "/en-de/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-de"
  }, {
    path: "/en-de/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-de"
  }, {
    path: "/en-de/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-de"
  }, {
    path: "/en-de/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-de"
  }, {
    path: "/en-de/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-de"
  }, {
    path: "/en-de/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-de"
  }, {
    path: "/en-dk/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-dk"
  }, {
    path: "/en-dk/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-dk"
  }, {
    path: "/en-dk/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-dk"
  }, {
    path: "/en-dk/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-dk"
  }, {
    path: "/en-dk/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-dk"
  }, {
    path: "/en-dk/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-dk"
  }, {
    path: "/en-es/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-es"
  }, {
    path: "/en-es/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-es"
  }, {
    path: "/en-es/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-es"
  }, {
    path: "/en-es/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-es"
  }, {
    path: "/en-es/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-es"
  }, {
    path: "/en-es/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-es"
  }, {
    path: "/en-fr/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-fr"
  }, {
    path: "/en-fr/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-fr"
  }, {
    path: "/en-fr/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-fr"
  }, {
    path: "/en-fr/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-fr"
  }, {
    path: "/en-fr/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-fr"
  }, {
    path: "/en-fr/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-fr"
  }, {
    path: "/en-it/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-it"
  }, {
    path: "/en-it/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-it"
  }, {
    path: "/en-it/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-it"
  }, {
    path: "/en-it/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-it"
  }, {
    path: "/en-it/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-it"
  }, {
    path: "/en-it/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-it"
  }, {
    path: "/en-nl/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-nl"
  }, {
    path: "/en-nl/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-nl"
  }, {
    path: "/en-nl/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-nl"
  }, {
    path: "/en-nl/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-nl"
  }, {
    path: "/en-nl/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-nl"
  }, {
    path: "/en-nl/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-nl"
  }, {
    path: "/en-se/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en-se"
  }, {
    path: "/en-se/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en-se"
  }, {
    path: "/en-se/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en-se"
  }, {
    path: "/en-se/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en-se"
  }, {
    path: "/en-se/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en-se"
  }, {
    path: "/en-se/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en-se"
  }, {
    path: "/es/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___es"
  }, {
    path: "/es/auth/rellamada/:id",
    component: _684a411f,
    name: "checkout-callback-id___es"
  }, {
    path: "/es/auth/rellamada/:slug",
    component: _426099e6,
    name: "auth-callback-slug___es"
  }, {
    path: "/es/blogs/novedades/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___es"
  }, {
    path: "/es/caja/exito/:id",
    component: _98579e62,
    name: "checkout-success-id___es"
  }, {
    path: "/es/cuenta/pedido/:id",
    component: _e7e4efc6,
    name: "account-order-id___es"
  }, {
    path: "/fr-be/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___fr-be"
  }, {
    path: "/fr-be/auth/rappel/:id",
    component: _684a411f,
    name: "checkout-callback-id___fr-be"
  }, {
    path: "/fr-be/auth/rappel/:slug",
    component: _426099e6,
    name: "auth-callback-slug___fr-be"
  }, {
    path: "/fr-be/blogs/nouveaute/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___fr-be"
  }, {
    path: "/fr-be/caisse/succes/:id",
    component: _98579e62,
    name: "checkout-success-id___fr-be"
  }, {
    path: "/fr-be/compte/commande/:id",
    component: _e7e4efc6,
    name: "account-order-id___fr-be"
  }, {
    path: "/fr/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___fr"
  }, {
    path: "/fr/auth/rappel/:id",
    component: _684a411f,
    name: "checkout-callback-id___fr"
  }, {
    path: "/fr/auth/rappel/:slug",
    component: _426099e6,
    name: "auth-callback-slug___fr"
  }, {
    path: "/fr/blogs/nouveaute/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___fr"
  }, {
    path: "/fr/caisse/succes/:id",
    component: _98579e62,
    name: "checkout-success-id___fr"
  }, {
    path: "/fr/compte/commande/:id",
    component: _e7e4efc6,
    name: "account-order-id___fr"
  }, {
    path: "/ie/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___ie"
  }, {
    path: "/ie/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___ie"
  }, {
    path: "/ie/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___ie"
  }, {
    path: "/ie/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___ie"
  }, {
    path: "/ie/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___ie"
  }, {
    path: "/ie/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___ie"
  }, {
    path: "/us/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___us"
  }, {
    path: "/us/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___us"
  }, {
    path: "/us/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___us"
  }, {
    path: "/us/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___us"
  }, {
    path: "/us/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___us"
  }, {
    path: "/us/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___us"
  }, {
    path: "/account/addresses/:id",
    component: _12b67b1e,
    name: "account-addresses-id___en"
  }, {
    path: "/account/order/:id",
    component: _e7e4efc6,
    name: "account-order-id___en"
  }, {
    path: "/auth/callback/:slug",
    component: _426099e6,
    name: "auth-callback-slug___en"
  }, {
    path: "/blogs/news/:slug",
    component: _1a1b2e14,
    name: "blogs-news-slug___en"
  }, {
    path: "/checkout/callback/:id",
    component: _684a411f,
    name: "checkout-callback-id___en"
  }, {
    path: "/checkout/success/:id",
    component: _98579e62,
    name: "checkout-success-id___en"
  }, {
    path: "/de-at/produkte/:slug",
    component: _685d4209,
    name: "products-slug___de-at"
  }, {
    path: "/de-at/sammlungen/:slug",
    component: _4fa75bce,
    name: "collections-slug___de-at"
  }, {
    path: "/de-at/seiten/:slug",
    component: _6fdb1a05,
    name: "pages-slug___de-at"
  }, {
    path: "/de-be/produkte/:slug",
    component: _685d4209,
    name: "products-slug___de-be"
  }, {
    path: "/de-be/sammlungen/:slug",
    component: _4fa75bce,
    name: "collections-slug___de-be"
  }, {
    path: "/de-be/seiten/:slug",
    component: _6fdb1a05,
    name: "pages-slug___de-be"
  }, {
    path: "/de/produkte/:slug",
    component: _685d4209,
    name: "products-slug___de"
  }, {
    path: "/de/sammlungen/:slug",
    component: _4fa75bce,
    name: "collections-slug___de"
  }, {
    path: "/de/seiten/:slug",
    component: _6fdb1a05,
    name: "pages-slug___de"
  }, {
    path: "/en-be/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-be"
  }, {
    path: "/en-be/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-be"
  }, {
    path: "/en-be/products/:slug",
    component: _685d4209,
    name: "products-slug___en-be"
  }, {
    path: "/en-ca/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-ca"
  }, {
    path: "/en-ca/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-ca"
  }, {
    path: "/en-ca/products/:slug",
    component: _685d4209,
    name: "products-slug___en-ca"
  }, {
    path: "/en-ch/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-ch"
  }, {
    path: "/en-ch/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-ch"
  }, {
    path: "/en-ch/products/:slug",
    component: _685d4209,
    name: "products-slug___en-ch"
  }, {
    path: "/en-de/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-de"
  }, {
    path: "/en-de/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-de"
  }, {
    path: "/en-de/products/:slug",
    component: _685d4209,
    name: "products-slug___en-de"
  }, {
    path: "/en-dk/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-dk"
  }, {
    path: "/en-dk/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-dk"
  }, {
    path: "/en-dk/products/:slug",
    component: _685d4209,
    name: "products-slug___en-dk"
  }, {
    path: "/en-es/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-es"
  }, {
    path: "/en-es/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-es"
  }, {
    path: "/en-es/products/:slug",
    component: _685d4209,
    name: "products-slug___en-es"
  }, {
    path: "/en-fr/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-fr"
  }, {
    path: "/en-fr/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-fr"
  }, {
    path: "/en-fr/products/:slug",
    component: _685d4209,
    name: "products-slug___en-fr"
  }, {
    path: "/en-it/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-it"
  }, {
    path: "/en-it/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-it"
  }, {
    path: "/en-it/products/:slug",
    component: _685d4209,
    name: "products-slug___en-it"
  }, {
    path: "/en-nl/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-nl"
  }, {
    path: "/en-nl/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-nl"
  }, {
    path: "/en-nl/products/:slug",
    component: _685d4209,
    name: "products-slug___en-nl"
  }, {
    path: "/en-se/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en-se"
  }, {
    path: "/en-se/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en-se"
  }, {
    path: "/en-se/products/:slug",
    component: _685d4209,
    name: "products-slug___en-se"
  }, {
    path: "/es/colleciones/:slug",
    component: _4fa75bce,
    name: "collections-slug___es"
  }, {
    path: "/es/paginas/:slug",
    component: _6fdb1a05,
    name: "pages-slug___es"
  }, {
    path: "/es/productos/:slug",
    component: _685d4209,
    name: "products-slug___es"
  }, {
    path: "/fr-be/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___fr-be"
  }, {
    path: "/fr-be/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___fr-be"
  }, {
    path: "/fr-be/produits/:slug",
    component: _685d4209,
    name: "products-slug___fr-be"
  }, {
    path: "/fr/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___fr"
  }, {
    path: "/fr/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___fr"
  }, {
    path: "/fr/produits/:slug",
    component: _685d4209,
    name: "products-slug___fr"
  }, {
    path: "/ie/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___ie"
  }, {
    path: "/ie/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___ie"
  }, {
    path: "/ie/products/:slug",
    component: _685d4209,
    name: "products-slug___ie"
  }, {
    path: "/us/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___us"
  }, {
    path: "/us/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___us"
  }, {
    path: "/us/products/:slug",
    component: _685d4209,
    name: "products-slug___us"
  }, {
    path: "/de-at/wunschliste/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___de-at"
  }, {
    path: "/de-be/wunschliste/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___de-be"
  }, {
    path: "/de/wunschliste/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___de"
  }, {
    path: "/en-be/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-be"
  }, {
    path: "/en-ca/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-ca"
  }, {
    path: "/en-ch/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-ch"
  }, {
    path: "/en-de/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-de"
  }, {
    path: "/en-dk/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-dk"
  }, {
    path: "/en-es/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-es"
  }, {
    path: "/en-fr/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-fr"
  }, {
    path: "/en-it/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-it"
  }, {
    path: "/en-nl/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-nl"
  }, {
    path: "/en-se/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en-se"
  }, {
    path: "/es/favoritos/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___es"
  }, {
    path: "/fr-be/favoris/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___fr-be"
  }, {
    path: "/fr/favoris/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___fr"
  }, {
    path: "/ie/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___ie"
  }, {
    path: "/us/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___us"
  }, {
    path: "/de-at/sammlungen/:slug/produkte/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___de-at"
  }, {
    path: "/de-be/sammlungen/:slug/produkte/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___de-be"
  }, {
    path: "/de/sammlungen/:slug/produkte/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___de"
  }, {
    path: "/en-be/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-be"
  }, {
    path: "/en-ca/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-ca"
  }, {
    path: "/en-ch/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-ch"
  }, {
    path: "/en-de/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-de"
  }, {
    path: "/en-dk/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-dk"
  }, {
    path: "/en-es/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-es"
  }, {
    path: "/en-fr/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-fr"
  }, {
    path: "/en-it/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-it"
  }, {
    path: "/en-nl/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-nl"
  }, {
    path: "/en-se/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en-se"
  }, {
    path: "/es/colleciones/:slug/productos/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___es"
  }, {
    path: "/fr-be/collections/:slug/produits/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___fr-be"
  }, {
    path: "/fr/collections/:slug/produits/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___fr"
  }, {
    path: "/ie/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___ie"
  }, {
    path: "/us/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___us"
  }, {
    path: "/de-at/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___de-at"
  }, {
    path: "/de-be/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___de-be"
  }, {
    path: "/de/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___de"
  }, {
    path: "/en-be/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-be"
  }, {
    path: "/en-ca/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-ca"
  }, {
    path: "/en-ch/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-ch"
  }, {
    path: "/en-de/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-de"
  }, {
    path: "/en-dk/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-dk"
  }, {
    path: "/en-es/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-es"
  }, {
    path: "/en-fr/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-fr"
  }, {
    path: "/en-it/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-it"
  }, {
    path: "/en-nl/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-nl"
  }, {
    path: "/en-se/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en-se"
  }, {
    path: "/es/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___es"
  }, {
    path: "/fr-be/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___fr-be"
  }, {
    path: "/fr/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___fr"
  }, {
    path: "/ie/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___ie"
  }, {
    path: "/us/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___us"
  }, {
    path: "/collections/:slug",
    component: _4fa75bce,
    name: "collections-slug___en"
  }, {
    path: "/de-at/:slug",
    component: _2c447510,
    name: "slug___de-at"
  }, {
    path: "/de-be/:slug",
    component: _2c447510,
    name: "slug___de-be"
  }, {
    path: "/de/:slug",
    component: _2c447510,
    name: "slug___de"
  }, {
    path: "/en-be/:slug",
    component: _2c447510,
    name: "slug___en-be"
  }, {
    path: "/en-ca/:slug",
    component: _2c447510,
    name: "slug___en-ca"
  }, {
    path: "/en-ch/:slug",
    component: _2c447510,
    name: "slug___en-ch"
  }, {
    path: "/en-de/:slug",
    component: _2c447510,
    name: "slug___en-de"
  }, {
    path: "/en-dk/:slug",
    component: _2c447510,
    name: "slug___en-dk"
  }, {
    path: "/en-es/:slug",
    component: _2c447510,
    name: "slug___en-es"
  }, {
    path: "/en-fr/:slug",
    component: _2c447510,
    name: "slug___en-fr"
  }, {
    path: "/en-it/:slug",
    component: _2c447510,
    name: "slug___en-it"
  }, {
    path: "/en-nl/:slug",
    component: _2c447510,
    name: "slug___en-nl"
  }, {
    path: "/en-se/:slug",
    component: _2c447510,
    name: "slug___en-se"
  }, {
    path: "/es/:slug",
    component: _2c447510,
    name: "slug___es"
  }, {
    path: "/fr-be/:slug",
    component: _2c447510,
    name: "slug___fr-be"
  }, {
    path: "/fr/:slug",
    component: _2c447510,
    name: "slug___fr"
  }, {
    path: "/ie/:slug",
    component: _2c447510,
    name: "slug___ie"
  }, {
    path: "/pages/:slug",
    component: _6fdb1a05,
    name: "pages-slug___en"
  }, {
    path: "/products/:slug",
    component: _685d4209,
    name: "products-slug___en"
  }, {
    path: "/us/:slug",
    component: _2c447510,
    name: "slug___us"
  }, {
    path: "/wishlists/:id/edit",
    component: _36cfaf6c,
    name: "wishlists-id-edit___en"
  }, {
    path: "/collections/:slug/products/:prodslug",
    component: _062db13f,
    name: "collections-slug-products-prodslug___en"
  }, {
    path: "/wishlists/:id?/:slug?",
    component: _23240d0c,
    name: "wishlists-id-slug___en"
  }, {
    path: "/en-it/*",
    component: _2efaf336,
    name: "all___en-it"
  }, {
    path: "/en-nl/*",
    component: _2efaf336,
    name: "all___en-nl"
  }, {
    path: "/en-ch/*",
    component: _2efaf336,
    name: "all___en-ch"
  }, {
    path: "/en-ca/*",
    component: _2efaf336,
    name: "all___en-ca"
  }, {
    path: "/en-se/*",
    component: _2efaf336,
    name: "all___en-se"
  }, {
    path: "/en-dk/*",
    component: _2efaf336,
    name: "all___en-dk"
  }, {
    path: "/en-es/*",
    component: _2efaf336,
    name: "all___en-es"
  }, {
    path: "/es/*",
    component: _2efaf336,
    name: "all___es"
  }, {
    path: "/en-be/*",
    component: _2efaf336,
    name: "all___en-be"
  }, {
    path: "/de-be/*",
    component: _2efaf336,
    name: "all___de-be"
  }, {
    path: "/fr-be/*",
    component: _2efaf336,
    name: "all___fr-be"
  }, {
    path: "/en-fr/*",
    component: _2efaf336,
    name: "all___en-fr"
  }, {
    path: "/fr/*",
    component: _2efaf336,
    name: "all___fr"
  }, {
    path: "/en-de/*",
    component: _2efaf336,
    name: "all___en-de"
  }, {
    path: "/de-at/*",
    component: _2efaf336,
    name: "all___de-at"
  }, {
    path: "/de/*",
    component: _2efaf336,
    name: "all___de"
  }, {
    path: "/us/*",
    component: _2efaf336,
    name: "all___us"
  }, {
    path: "/ie/*",
    component: _2efaf336,
    name: "all___ie"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/:slug",
    component: _2c447510,
    name: "slug___en"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
