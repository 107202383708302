import { screens } from '@/plugins/enums'

export const state = () => ({
  sider: null,
  siderTimeout: null,
  sidebar: false,
  navbar: null,
  size: 'sm',
  isMobile: false,
  clearHiderTimer: false,
  footer: 'default',
  darkMode: true,
  addToBagFeedback: false,
  expressLoaderModal: false,
})

export const getters = {
  activeSider: (state) => state.sider,
  siderTimeout: (state) => state.siderTimeout,
  isWishlistActive: (state) => state.sider === 'wishlist',
  isCartActive: (state) => state.sider === 'cart',
  isAccountActive: (state) => state.sider === 'account',
  isSidebarActive: (state) => state.sidebar,
  isNavbarActive: (state) => state.navbar !== null,
  shouldClearHiderTimer: (state) => state.clearHiderTimer,
  footer: (state) => state.footer,
  darkMode: (state) => state.darkMode,
  isAddToBagFeedbackActive: (state) => state.addToBagFeedback,
  expressLoaderModal: (state) => state.expressLoaderModal,
  isMobile: (state) => state.isMobile,
}

export const mutations = {
  toggleSider(state, name) {
    if (state.sider === name) {
      state.sider = null
      return
    }

    state.sidebar = false
    state.sider = name
  },

  setSiderTimeout(state, timeout) {
    state.siderTimeout = timeout
  },

  clearHiderTimer(state, status) {
    state.clearHiderTimer = status
  },
  showSider(state, name) {
    state.sidebar = false
    state.sider = name
  },
  hideSider(state) {
    state.sider = null
  },
  showNavbar(state, name) {
    state.navbar = name
  },
  hideNavbar(state, name) {
    if (state.navbar === name) {
      state.navbar = null
    }
  },
  toggleSidebar(state, value) {
    state.sidebar = typeof value === 'boolean' ? value : !state.sidebar
    state.sider = null
  },
  closeAll(state) {
    state.sider = null
    state.sidebar = false
    state.navbar = null
    state.addToBagFeedback = false
  },
  updateSize(state, width) {
    if (width <= screens.sm) {
      state.size = 'sm'
    } else if (width > screens.sm && width <= screen.md) {
      state.size = 'md'
    } else if (width > screens.md && width <= screen.lg) {
      state.size = 'lg'
    } else {
      state.size = 'xl'
    }

    if (width <= screens.mobile) {
      state.isMobile = true
    } else {
      state.isMobile = false
    }
  },
  setFooter(state, footer = 'default') {
    state.footer = footer
  },
  setAddToBagFeedback(state, value) {
    state.addToBagFeedback = value
  },
  setExpressLoader(state, data) {
    state.expressLoaderModal = data
  },
}

export const actions = {
  async sendLog({ commit, getters }, { level, message, context }) {
    const errorPayload = {
      level,
      message,
      context,
    }
    return await this.$axios.$post('log', { ...errorPayload })
  },

  toggleSider({ commit }, name) {
    return commit('toggleSider', name)
  },

  toggleSiderForAWhile({ commit, getters }, { name, time }) {
    commit('showSider', name)
    // console.log('set hide sider timeout')
    const timeout = setTimeout(() => {
      if (getters.activeSider === name) {
        // console.log(name, ' hided in ', time, ' milliseconds')
        commit('hideSider', name)
      }
    }, time)

    commit('setSiderTimeout', timeout)
  },

  hideSiderInAWhile({ commit, getters }, { name, time }) {
    // console.log('set hide sider timeout')
    const timeout = setTimeout(() => {
      if (getters.activeSider === name) {
        // console.log(name, ' hided in ', time, ' milliseconds')
        commit('hideSider', name)
      }
    }, time)

    commit('setSiderTimeout', timeout)
  },
  clearHideSiderTimeout({ state }) {
    // console.log('clear hide sider timeout')
    clearTimeout(state.siderTimeout)
  },

  doClearHiderTimer({ commit }, status) {
    return commit('clearHiderTimer', status)
  },
  doShowSider({ commit }, name) {
    return commit('showSider', name)
  },
  doHideSider({ commit }, name) {
    return commit('hideSider', name)
  },
  toggleSidebar({ commit }, value) {
    return commit('toggleSidebar', value)
  },
  closeAll({ commit }) {
    return commit('closeAll')
  },
  updateSize({ commit }, width) {
    return commit('updateSize', width)
  },
  doShowNavbar({ commit }, name) {
    return commit('showNavbar', name)
  },
  doHideNavbar({ commit, dispatch }, name) {
    return commit('hideNavbar', name)
  },
  setFooter({ commit }, footer) {
    return commit('setFooter', footer)
  },

  setAddToBagFeedback({ commit }, value) {
    commit('setAddToBagFeedback', value)
  },

  toggleAddToBagFeedbackForAWhile({ commit, rootGetters }, time) {
    if (rootGetters['cart/lastAddedItem']) {
      commit('setAddToBagFeedback', true)

      setTimeout(() => {
        commit('setAddToBagFeedback', false)
      }, time)
    }
  },
  expressLoaderModalSetter({ commit }, data) {
    commit('setExpressLoader', data)
  },
}
