import { SyncLocalStorage } from '@/plugins/syncLocalStorage'
import {
  cartParams,
  cartProductForDataLayer,
  cartRemapper,
  getCookie,
} from '@/plugins/utilities'
import { addToCart as fbAddToCart } from '@/plugins/fb-pixel-helper'
import { addToCart, removeFromCart } from '@/plugins/gtm-helper'
import { abandonedCartUpdate as faAbandonedCartUpdate } from '@/plugins/pushalert-helper'
import {
  addToCartMicrosoft,
  shoppingCartMicrosoft,
} from '@/plugins/microsoft-tracking-helper'

export const state = () => ({
  items: [],
  order: {},
  isLoading: false,
  lastAddedItem: null,
  metaCategory: null,
  freeProductDiscount: null,
  isWaitingForFreeProductDiscountCheck: false,
})

export const getters = {
  total(state) {
    return state.items.reduce(
      (p, x) =>
        x.isFreeCart ? x.quantity + p : x.quantity - x.free_product_count + p,
      0
    )
  },

  totalPrice(state) {
    /* return state.items
      .reduce((p, x) => x.product.currency_price + p, 0)
      .toFixed(2) */
    return state.order.currency_total
  },

  loading(state) {
    return state.isLoading
  },

  items(state) {
    return state.items
  },

  orderDataV(state) {
    return state.order
  },

  lastAddedItem(state) {
    return state.lastAddedItem
  },

  getMetaCategory(state) {
    return state.metaCategory
  },

  freeProductDiscount(state) {
    return state.freeProductDiscount
  },
}

export const mutations = {
  setLoading(state, loading) {
    state.isLoading = loading
  },

  set(state, items) {
    state.items = items
  },

  empty(state) {
    state.items = []
    state.order = {}
  },

  setLastAdded(state, item) {
    state.lastAddedItem = item
  },

  setItemId(state, payload) {
    const { idx, id } = payload
    state.items[idx].id = id || state.items[idx].id
  },

  setOrder(state, data) {
    state.order = data
  },

  setMetaCategory(state, data) {
    state.metaCategory = data
  },

  setFreeProductDiscount(state, data) {
    state.freeProductDiscount = data
  },
  // setQuantity(state, payload) {
  //   const { cart, quantity } = payload
  //   const currCart = state.items.find(
  //     (item) => item.product_variant_id === cart.product_variant_id
  //   )
  //   currCart.quantity = quantity
  // },

  checkFreeProductDiscount(state) {
    state.isWaitingForFreeProductDiscountCheck = false

    if (!state || !state.order) {
      state.freeProductDiscount = null
    } else {
      const freeProductDiscount = state.order.discounts
        ? state.order.discounts.find((d) => d.type === 6)
        : null

      if (freeProductDiscount) {
        const hasAddedAnyFreeProductToOrder = state.order.orderProducts.some(
          (p) => {
            if (
              p.price === 0 &&
              p.discount &&
              p.discount.id === freeProductDiscount.id
            ) {
              return true
            }

            return false
          }
        )

        if (!hasAddedAnyFreeProductToOrder) {
          state.freeProductDiscount = freeProductDiscount
        } else {
          state.freeProductDiscount = null
        }
      } else {
        state.freeProductDiscount = null
      }
    }
  },

  setWaitingForFreeProductDiscountCheck(state, data) {
    state.isWaitingForFreeProductDiscountCheck = data
  },
}

export const actions = {
  // add this method to layout's mount hook
  async browserInit({ dispatch, commit, state }) {
    const sessionId = await SyncLocalStorage('cart.sessionId')
    return this.$axios
      .$post('orders/get', {
        session_id: sessionId,
      })
      .then((response) => {
        dispatch('fillCartData', response.data)
        dispatch('fillCheckoutData', response.data)
        dispatch('session/setSession', response.data.session_id, { root: true })

        if (state.isWaitingForFreeProductDiscountCheck) {
          commit('checkFreeProductDiscount')
        }

        return response.data
      })
  },

  async addToCart({ commit, state, dispatch, getters }, product) {
    commit('setLoading', true)
    const sessionId = await SyncLocalStorage('cart.sessionId')

    return this.$axios
      .$post('/orders/add', {
        ...cartParams(product),
        session_id: sessionId,
      })
      .then((result) => {
        dispatch('session/setSession', result.data.session_id, { root: true })
        dispatch('fillCartData', result.data)
        dispatch('fillCheckoutData', result.data)
        // Clear checkout
        // dispatch('checkout/clear', null, { root: true })
        // dispatch('checkout/setOrder', result.data)

        // product id is equal to product_variant_id in here
        let addedItem = result.data.orderProducts.find(
          (x) =>
            x.product_variant_id === (product.product_variant_id || product.id)
        )
        const metaCategory = product.metaCategory
        commit('setMetaCategory', metaCategory)

        addedItem = { ...addedItem, metaCategory }

        if (addedItem) {
          // GTM: Add to cart
          addToCart(addedItem)

          // Facebook: Add to cart
          fbAddToCart(this.$fb, addedItem)

          // Pushalert: Abandoned Cart Update
          faAbandonedCartUpdate(this.$auth, addedItem, getters.total)

          // Microsoft: Add to cart
          addToCartMicrosoft({ ...addedItem, currency: getCookie('currency') })
          // Microsoft: Shopping cart
          shoppingCartMicrosoft(result.data)

          commit('setLastAdded', addedItem)
          commit('checkFreeProductDiscount')
        }
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  // remove a product from bag
  async remove({ commit, state, dispatch }, product) {
    commit('setLoading', true)
    const sessionId = await SyncLocalStorage('cart.sessionId')

    const removedItem = state.items.find(
      (x) => x.product_variant_id === product.product_variant_id
    )

    return this.$axios
      .$post('/orders/remove', {
        ...cartParams(product),
        session_id: sessionId,
      })
      .then((result) => {
        // dispatch('handleFreeItems', result.data.orderProducts)
        // dispatch('session/setSession', result.data.session_id, { root: true })

        // Clear checkout
        dispatch('checkout/clear', null, { root: true })
        // dispatch('fillCheckoutData', result.data)

        dispatch('session/setSession', result.data.session_id, { root: true })
        dispatch('fillCartData', result.data)
        dispatch('fillCheckoutData', result.data)
        commit('checkFreeProductDiscount')

        // GTM: Remove from cart
        removeFromCart(removedItem)

        shoppingCartMicrosoft(result.data)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  async emptyCart({ commit, state, dispatch }, order) {
    const sessionId = await SyncLocalStorage('cart.sessionId')

    commit('empty')

    // Clear checkout
    dispatch('checkout/clear', null, { root: true })

    if (order && (order.id || order.order_payment_id)) {
      const url = order.order_payment_id
        ? `/orders/clearByOrderPayment/${order.order_payment_id}`
        : `/orders/clearByOrder/${order.id}`

      commit('setLoading', true)
      this.$axios
        .$post(url, {
          session_id: sessionId,
        })
        .then((result) => {
          dispatch('fillCheckoutData', result.data)
          shoppingCartMicrosoft(result.data)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  },

  // This just clear the items and order data in store
  emptyCartAndOrder({ commit }) {
    commit('empty')
  },

  async updateQuantity({ commit, state, dispatch }, { cart, quantity }) {
    commit('setLoading', true)
    const sessionId = await SyncLocalStorage('cart.sessionId')

    return this.$axios
      .$put('/orders/update', {
        quantity,
        product_variant_id: cart.product_variant_id,
        session_id: sessionId,
      })
      .then((result) => {
        // dispatch('session/setSession', result.data.session_id, { root: true })
        dispatch('handleFreeItems', result.data.orderProducts)
        commit('setOrder', result.data)
        dispatch('fillCheckoutData', result.data)

        // Clear checkout
        // dispatch('checkout/clear', null, { root: true })

        // GTM: Remove from cart
        // window.dataLayer.push({
        //   event: 'removeFromCart',
        //   ecommerce: {
        //     remove: {
        //       products: [cartProductForDataLayer(decreasedItem)],
        //     },
        //   },
        // })
        shoppingCartMicrosoft(result.data)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  handleFreeItems({ commit }, items) {
    if (items) {
      const cartItems = JSON.parse(JSON.stringify(items))
      const freeItems = []
      cartItems.forEach((element) => {
        const freeItem = { ...element }
        if (freeItem.free_product_count > 0) {
          freeItem.quantity = freeItem.free_product_count
          freeItem.currency_price = 0
          freeItem.currency_undiscounted_price = element.productVariant.price
          freeItem.isFreeCart = true
          freeItems.push(freeItem)
          // element.quantity -= element.free_product_count
        }
      })

      const c = cartItems.filter((x) => {
        return x.quantity !== x.free_product_count
      })
      c.push(...freeItems)

      const cartItemsWithFree = c.map(cartRemapper)
      commit('set', cartItemsWithFree)
    } else {
      commit('set', [])
    }
    SyncLocalStorage('cart.items')
  },

  // Set the order data in store. It contains subTotal, total etc.
  orderData({ commit }, data) {
    commit('setOrder', data)
  },

  async updateCartItemsResponseFromExpress({ state, commit }, cartObj) {
    // commit('set', cart)
    await SyncLocalStorage(
      'cart.items',
      cartObj
        .filter((m) => !m.isFreeCart)
        .map((m) => {
          return {
            cart_id: m.id,
            quantity: m.quantity,
            product_id: m.product.id,
            product_variant_id: m.product.product_variant_id,
            order: m.order,
            has_variants: m.product.has_variants,
          }
        })
    )
    state.items.forEach((item, idx) => {
      if (cartObj[idx].id !== item.id) {
        const newId = cartObj[idx].id
        const payload = {
          idx,
          id: newId,
        }
        commit('setItemId', payload)
      }
    })
  },

  // fill checkout store fresh data
  fillCheckoutData({ dispatch }, data) {
    const d = data.order ? data.order : data
    const order = {
      order_id: d.id,
      user_id: d.user_id,
      email: d.email,
    }
    const checkoutOrder = {
      order: { ...d },
    }
    dispatch('checkout/fillOrderData', order, { root: true })
    dispatch('checkout/fillCheckoutData', checkoutOrder, { root: true })
  },

  // Fill cart data outside cart store
  fillCartData({ dispatch }, data) {
    const d = data.order ? data.order : data
    dispatch('orderData', d)
    dispatch('handleFreeItems', d.orderProducts)
  },

  setFreeProductDiscount({ commit }, data) {
    commit('setFreeProductDiscount', data)
  },

  checkFreeProductDiscount({ commit }) {
    commit('checkFreeProductDiscount')
  },

  setWaitingForFreeProductDiscountCheck({ commit }, data) {
    commit('setWaitingForFreeProductDiscountCheck', data)
  },
}
