//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { virtualPageView } from '@/plugins/gtm-helper'

import Container from '@/components/Container'
import notFoundImage from '@/assets/img/404.webp'

export default {
  components: {
    Container,
    ErrorContent: () => import('@/containers/error/ErrorContent'),
  },
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notFoundImage,
    }
  },
  head() {
    return {
      titleTemplate: '%s',
      title: this.isNotFound ? this.$t('not_found') : this.$t('server_error'),
    }
  },
  computed: {
    isNotFound() {
      return this.error.statusCode === 404
    },
  },
  mounted() {
    setTimeout(() => {
      // GTM: virtualPageView
      virtualPageView(
        this.$route,
        'Other',
        this.isNotFound ? this.$t('not_found') : this.$t('server_error')
      )
    }, 250)
  },
}
