export const SyncLocalStorage = (key, value = null) => {
  if (!value) {
    const localValue = getLocalStorage(key)
    return localValue || null
  }

  setLocalStorage(key, value)
  return value
}

export const getLocalStorage = (key) => {
  const item = window.localStorage.getItem(key)
  return item ? JSON.parse(item) : undefined
}

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorage = (key) => {
  window.localStorage.removeItem(key)
}

const syncCart = (mutation, state) => {
  if (mutation.type === 'cart/set') {
    SyncLocalStorage(
      'cart.items',
      state.cart.items
        .filter((m) => !m.isFreeCart)
        .map((m) => {
          return {
            cart_id: m.id,
            quantity: m.quantity,
            product_id: m.product.id,
            product_variant_id: m.product.product_variant_id,
            order: m.order,
            has_variants: m.product.has_variants,
          }
        })
    )
  }

  if (mutation.type === 'cart/setSessionId') {
    SyncLocalStorage('cart.sessionId', state.session.sessionId)
  }
}

const syncWishlist = (mutation, state) => {
  if (mutation.type === 'wishlist/set') {
    SyncLocalStorage(
      'wishlist.items',
      state.wishlist.items.map((m, i) => {
        return {
          id: m.id,
          product_id: m.product_id,
          order: i,
        }
      })
    )
  }

  if (mutation.type === 'wishlist/setSessionId') {
    SyncLocalStorage('wishlist.sessionId', state.wishlist.sessionId)
  }
}

const syncRecentlyViewed = (mutation, state) => {
  if (mutation.type === 'recentlyViewed/set') {
    SyncLocalStorage('recentlyViewed.items', state.recentlyViewed.items)
  }
}

export default function SyncLocalStoragePlugin({ store }) {
  store.subscribe((mutation, state) => {
    if (mutation.type.startsWith('recentlyViewed/')) {
      if (state.recentlyViewed.items.length > 1)
        SyncLocalStorage('recentlyViewed.items', state.recentlyViewed.items)
    }

    syncCart(mutation, state)
    syncWishlist(mutation, state)
    syncRecentlyViewed(mutation, state)
  })
}
