import {
  SyncLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from '@/plugins/syncLocalStorage'

export const state = () => {
  return {
    order: null,
    checkout: null,
    selectedPaymentMethod: null,
  }
}

export const mutations = {
  setOrder(state, data) {
    state.order = data
  },
  setSelectedPaymentMethod(state, data) {
    state.selectedPaymentMethod = data
  },
  setCheckout(state, data) {
    if (!state.checkout || !data) {
      state.checkout = data
      return
    }

    if (
      (state.checkout.addresses && data.addresses) ||
      (!state.checkout.addresses && data.addresses)
    ) {
      state.checkout.addresses = [...data.addresses]
    }
    if (
      (state.checkout.creditCarts && data.creditCarts) ||
      (!state.checkout.creditCarts && data.creditCarts)
    ) {
      state.checkout.creditCarts = [...data.creditCarts]
    }
    if (data.order) {
      state.checkout.order = { ...data.order }
    }
    /* state.checkout = {
      ...data,
      order: {
        ...data.order,
      },
    } */
  },
  customerChangeOnCheckout(state, data) {
    state.checkout.order.shippingOrderAddress = data.addresses[0]
    state.checkout.order.shippingOrderAddress.address_id = data.addresses[0].id
    state.checkout.order.shipping_order_address_id = data.addresses[0].id

    state.checkout.order.billingOrderAddress = data.addresses[0]
    state.checkout.order.billing_order_address_id = data.addresses[0].id
  },
  addressChangeOnCheckoutInfo(state, data) {
    if (data.address) {
      state.checkout.order.shippingOrderAddress = data.address
      state.checkout.order.shippingOrderAddress.address_id = data.address.id
      state.checkout.order.shipping_order_address_id = data.address.id

      state.checkout.order.billingOrderAddress = data.address
      state.checkout.order.billing_order_address_id = data.address.id
    } else {
      state.checkout.order.shippingOrderAddress = Object.assign(
        {},
        {
          address_id: null,
          first_name: '',
          last_name: '',
          company_name: '',
          address_1: '',
          address_2: '',
          city: '',
          country_id: '',
          zip: '',
          province: '',
          autocomplete_id: null,
          autocomplete_type: 0,
          save: false,
        },
        { address_id: null }
      )
      state.checkout.order.billingOrderAddress = null
      state.checkout.order.billing_order_address_id = null
    }

    if (data.addresses && data.addresses.length)
      state.checkout.addresses = data.addresses
  },
}

export const getters = {
  order(state) {
    return state.order
  },
  getSelectedPaymentMethod(state) {
    return state.selectedPaymentMethod
  },
  checkout(state) {
    return state.checkout ? JSON.parse(JSON.stringify(state.checkout)) : null
  },
  checkoutSuccessBag(state) {
    if (state.checkout) {
      if (state.checkout.order) {
        return state.checkout.order.orderProducts
      }
    } else return []
  },
  checkoutSuccessOrder(state) {
    if (state.checkout) {
      if (state.checkout.order) {
        return state.checkout.order
      }
    } else return []
  },
}

export const actions = {
  async browserInit({ commit }) {
    const order = await SyncLocalStorage('checkout.order')
    commit('setOrder', order)
  },
  async fillOrderData({ commit }, order) {
    await SyncLocalStorage('checkout.order', order)
    commit('setOrder', order)
  },
  setSelectedPaymentMethod({ commit }, data) {
    return commit('setSelectedPaymentMethod', data)
  },
  fillCheckoutData({ commit }, checkout) {
    commit('setCheckout', checkout)
  },
  async getCheckout({ commit }) {
    const order = await SyncLocalStorage('checkout.order')
    const sessionId = await SyncLocalStorage('cart.sessionId')

    if (!order || !order.order_id) {
      console.log('no order created')
      return null
    }

    return this.$axios
      .$post(`checkout/${order.order_id}/show`, {
        session_id: sessionId,
      })
      .then((response) => {
        // response.data.order.subscribe = this.state.checkout.order.subscribe
        commit('setCheckout', response.data)
        return response.data
      })
  },
  // useless func
  async userCheckout({ commit, dispatch }) {
    // const items = (await SyncLocalStorage('cart.items')) || []
    const sessionId = await SyncLocalStorage('cart.sessionId')

    return this.$axios
      .$post('checkout/user', {
        session_id: sessionId,
      })
      .then((response) => {
        const order = {
          order_id: response.data.order.id,
          user_id: response.data.order.user_id,
        }
        commit('setOrder', order)
        commit('setCheckout', response.data)
        dispatch('cart/orderData', response.data.order, {
          root: true,
        })
        SyncLocalStorage('cart.sessionId', response.data.order.session_id)
        SyncLocalStorage('checkout.order', order)

        return response.data
      })
  },
  async guestCheckout({ commit, dispatch }) {
    // const items = (await SyncLocalStorage('cart.items')) || []
    // const checkoutOrder = (await SyncLocalStorage('checkout.order')) || {}
    const sessionId = await SyncLocalStorage('cart.sessionId')

    return this.$axios
      .$post('checkout/guest', {
        session_id: sessionId,
      })
      .then((response) => {
        const order = {
          order_id: response.data.order.id,
          email: response.data.order.email,
        }
        commit('setOrder', order)
        commit('setCheckout', response.data)
        dispatch('cart/fillCartData', response.data, {
          root: true,
        })
        SyncLocalStorage('cart.sessionId', response.data.order.session_id)
        SyncLocalStorage('checkout.order', order)

        return response.data
      })
  },
  checkoutInfo({ commit, dispatch }, data) {
    return this.$axios
      .$post(`checkout/${data.order.id}/info`, data)
      .then((response) => {
        response.data.order.email = data.order.email
        response.data.order.phone = data.order.phone
        commit('setCheckout', response.data)
        dispatch('cart/fillCartData', response.data, {
          root: true,
        })
        return response.data
      })
  },
  checkoutInfoRework({ commit, dispatch }, data) {
    return this.$axios
      .$post(`checkout/${data.order.id}/info-rework`, data)
      .then((response) => {
        response.data.order.subscribe = data.order.subscribe
        commit('setCheckout', response.data)
        dispatch('cart/fillCartData', response.data, {
          root: true,
        })

        this.state.checkout.order.subscribe = data.order.subscribe
        return response.data
      })
  },
  checkoutDelivery({ commit, dispatch }, data) {
    return this.$axios
      .$post(`checkout/${data.order.id}/delivery`, data)
      .then((response) => {
        commit('setCheckout', response.data)
        dispatch('cart/fillCartData', response.data, {
          root: true,
        })
        return response.data
      })
  },
  checkoutOrder({ commit }, data) {
    return this.$axios
      .$post(`checkout/${data.order.id}/order`, data)
      .then((response) => {
        commit('setOrder', null)
        commit('setCheckout', null)

        SyncLocalStorage('checkout.order', {})

        return response.data
      })
  },
  submitBillingAddress({ commit }, data) {
    return this.$axios
      .$post(`checkout/${data.order.id}/billingAddress`, data)
      .then((response) => {
        commit('setCheckout', response.data)

        return response.data
      })
  },
  applyDiscount({ commit, state, dispatch }, payload) {
    return this.$axios
      .$post(`checkout/${state.order.order_id}/applyDiscount`, payload)
      .then((response) => {
        // commit('setCheckout', response.data)
        dispatch('cart/fillCartData', response.data, { root: true })

        const discountCode = response.data.order.discount_code
        if (response.data.order) {
          setLocalStorage('cart.discountCode', discountCode)
        }

        return response.data
      })
  },
  removeDiscount({ commit, state, dispatch }, code) {
    return this.$axios
      .$post(`checkout/${state.order.order_id}/removeDiscount`, {
        code,
      })
      .then((response) => {
        // commit('setCheckout', response.data)
        dispatch('cart/fillCartData', response.data, { root: true })
        removeLocalStorage('cart.discountCode')

        return response.data
      })
  },
  setPassword({ rootGetters }, data) {
    return this.$axios
      .$post(`checkout/${data.order_id}/setPassword`, {
        ...data,
        session_id: rootGetters['cart/sessionId'],
      })
      .then((response) => {
        return response.data
      })
  },
  clear({ commit, dispatch }) {
    console.log('clear checkout')
    commit('setOrder', null)
    commit('setCheckout', null)
    SyncLocalStorage('checkout.order', {})
  },
  customerChangeOnCheckout({ commit }, addresses) {
    commit('customerChangeOnCheckout', addresses)
  },
}
