import { getCookie, setCookie } from '@/plugins/utilities'

const cookiesAcceptKey = 'ry_cookies_accepted'

export const state = () => ({
  showCookiesBar: false,
  accepted: false, // getCookie('ry_cookies_accepted')
})

export const getters = {
  isCookiesBarActive: (state) => state.showCookiesBar,
  isCookiesAccepted: (state) => state.accepted,
}

export const mutations = {
  setCookiesBar(state, value) {
    state.showCookiesBar = value
  },
  setAccepted(state, value) {
    state.accepted = value
  },
}

export const actions = {
  browserInit({ commit }) {
    const isAccepted = getCookie(cookiesAcceptKey)
    commit('setCookiesBar', !isAccepted)
    commit('setAccepted', !!isAccepted)
  },
  hideCookiesBar({ commit }) {
    return commit('setCookiesBar', false)
  },
  showCookiesBar({ commit }) {
    return commit('setCookiesBar', true)
  },
  acceptCookies({ commit }) {
    setCookie(cookiesAcceptKey, true, 365)
    commit('setCookiesBar', false)
    commit('setAccepted', true)
  },
}
