import { SyncLocalStorage } from '@/plugins/syncLocalStorage'

export const state = () => ({
  items: [],
})

export const mutations = {
  set(state, items) {
    state.items = items
  },

  empty(state) {
    state.items = []
  },
}

export const actions = {
  async browserInit({ commit }) {
    const items = (await SyncLocalStorage('recentlyViewed.items')) || []
    commit('set', items)
  },

  addRecentlyViewed({ commit, state }, item) {
    const product = {
      id: item.product_id || item.id,
      slug: item.slug,
      title: item.title,
      price: item.price,
      undiscounted_price: item.undiscounted_price,
      media: null,
    }

    if (item.media) {
      product.media = item.media
    } else if (item.gallery) {
      if (item.media_id) {
        product.media = item.gallery.find((m) => (m.id = item.media_id))
      } else if (item.product_variant_id && item.productVariants) {
        const productVariant = item.productVariants.find(
          (m) => m.id === item.product_variant_id
        )
        if (productVariant && productVariant.media_id) {
          product.media = item.gallery.find(
            (m) => m.id === productVariant.media_id
          )
        } else {
          product.media = item.gallery[0]
        }
      }
    }

    const otherItems = state.items.filter((m) => m.id !== product.id)
    let items = [product, ...otherItems]
    if (items.length > 4) {
      items = [...items.splice(0, 6)]
    }

    commit('set', items)
  },

  getRecentlyViews({ state }, id) {
    if (id) {
      return state.items.filter((m) => m.id !== id)
    }

    return state.items
  },
}
