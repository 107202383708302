import { locales } from '@/plugins/enums'

export const state = () => ({
  locale: null,
  locales,
})

export const getters = {
  getLocale: (state) => state.locale,
  getLocales: (state) => state.locales,
}

export const mutations = {
  setLocale(state, locale) {
    state.locale = locale.code
  },
}

export const actions = {
  changeLocale({ commit }, name) {
    return commit('setLocale', name)
  },
}
