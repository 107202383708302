/* eslint-disable */
import { onLoad } from '../plugins/utilities'

export default () => {
  onLoad(() => {
    var script2 = document.createElement('script')
    script2.type = 'text/javascript'
    var code = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-10847318695');
  `
    try {
      script2.appendChild(document.createTextNode(code))
      document.body.appendChild(script2)
    } catch (e) {
      script2.text = code
      document.body.appendChild(script2)
    }
  }, 3000)
}
