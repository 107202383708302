export const isCrawler = () => {
  try {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(
      navigator.userAgent
    )
  } catch (e) {
    //
  }

  return false
}
