/* eslint-disable */
import { onLoad } from '../plugins/utilities'

const devWebsiteId = 'integrate_1fff7a62d52be20c7818fe3ca04308f1'
const prodWebsiteId = 'integrate_5bb64cab447a9ebe5292ea81de95f90b'

export default ({ app }) => {
  onLoad(() => {
    ;(function (d, t) {
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0]
      g.src = `https://cdn.pushalert.co/${prodWebsiteId}.js`
      s.parentNode.insertBefore(g, s)
    })(document, 'script')
  }, 2000)
}
