import { isCrawler } from '~/plugins/crawler'

export default function ({ app, $fb }) {
  if (!isCrawler()) {
    $fb.enable()
  }

  const pageView = (route) => {
    const fb = (type) => {
      $fb.track('PageView', {
        event: 'PageView',
        pageType: type,
        pageUrl: route.fullPath,
        pageTitle: document && document.title,
      })
    }

    const name = route.name ? route.name.split('___')[0] : ''

    // FB pixel: virtualPageView
    switch (name) {
      case 'index':
        fb('Homepage')
        break
      case 'slug':
      case 'collections-slug':
      case 'products-slug':
      case 'checkout':
      case 'checkout-info':
      case 'checkout-delivery':
      case 'checkout-payment':
        // Do nothing
        break
      case 'men':
      case 'children':
      case 'sales':
      case 'blog-news':
      case 'blog-news-slug':
      case 'cart':
        fb(
          name
            .split('-')
            .filter((x) => !Object.keys(route.params).includes(x))
            .map((x) => `${x.slice(0, 1).toUpperCase()}${x.slice(1)}`)
            .join(' ')
        )
        break
      default: {
        const routeNameWords = name.split('-')
        if (routeNameWords[0] === 'checkout') {
          fb('Checkout Pages')
        } else if (routeNameWords[0] === 'wishlists') {
          fb('Wishlist Pages')
        } else if (routeNameWords[0] === 'account') {
          fb('Account Panel')
        } else {
          fb('Other')
        }
        break
      }
    }
  }

  app.router.afterEach((to) => {
    setTimeout(() => {
      pageView(to)
    }, 250)
  })
}
