export default function ({ app }) {
  const selectedLocale = app.i18n.locale
  const selectedCountry = app.i18n.locales.filter((locale) => {
    return locale.code === selectedLocale
  })

  const country = selectedCountry[0].code
  const alpha2 = selectedCountry[0].country.alpha2
  const currency = selectedCountry[0].currencyCode
  const detectLanguageModal = app.$cookies.get('detectLanguageModal') || false
  const shippingCountry = selectedCountry[0].id

  const data = {
    currency,
    country,
    alpha2,
    detectLanguageModal,
    shippingCountry,
  }

  app.store.commit('settings/setLanguage', data)

  app.i18n.onBeforeLanguageSwitch = (
    oldLocale,
    newLocale,
    isInitialSetup,
    context
  ) => {
    console.log(oldLocale, newLocale, isInitialSetup)
  }
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    console.log(oldLocale, newLocale)
  }
}
