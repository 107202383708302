/* eslint-disable import/no-duplicates */
/* eslint-disable camelcase */
import { extend } from 'vee-validate'
import {
  required,
  alpha,
  alpha_spaces,
  email,
  regex,
} from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message(field) {
    if (field !== '{field}') return `The ${field} field is required`
  },
})

extend('confirmed', {
  message: 'The Password confirmation does not match.',
  validate(value, args) {
    return value === args[0]
  },
})

extend('alpha', {
  ...alpha,
  message: 'This field must only contain alphabetic characters',
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'The field must contain only letters',
})

extend('email', {
  ...email,
  message: 'The Email must be a valid email address',
})

extend('password', {
  message: 'The Password must be at least 8 characters.',
  validate(value, args) {
    return value.length >= 8
  },
})

extend('regex', {
  ...regex,
  message(field, args) {
    return `The ${field} field contains invalid characters`
  },
})

extend('phone', {
  message(field, args) {
    return `Must be a valid phone number`
  },
  validate(value, args) {
    const MOBILEREG = /^\d{10}|^\d{11}$/
    const phonenumber = value.replace(/\s/g, '')
    return MOBILEREG.test(phonenumber)
  },
})
