import {
  removeLocalStorage,
  SyncLocalStorage,
} from '@/plugins/syncLocalStorage'

export const state = () => ({
  sessionId: null,
})

export const getters = {
  sessionId(state) {
    return state.sessionId
  },
}
export const mutations = {
  setSessionId(state, sessionId) {
    state.sessionId = sessionId
  },
}

export const actions = {
  async sessionInit({ commit }) {
    const sessionId = await SyncLocalStorage('cart.sessionId')
    commit('setSessionId', sessionId)
  },
  async setSession({ commit }, data) {
    await SyncLocalStorage('cart.sessionId', data)
    commit('setSessionId', data)
  },
  removeSession({ commit }) {
    removeLocalStorage('cart.sessionId')
    commit('setSessionId', null)
  },
}
