/* eslint-disable import/first */
export const sortManual = 'manual'
const sortPriceLowToHigh = 'low-to-high'
const sortPriceHighToLow = 'high-to-low'
const sortSoldCount = 'best-selling'
const sortNewest = 'newest'
const sortScore = 'score'
const sortRelevance = 'relevance'

export const sortOptions = {
  collection: {
    sortManual,
    sortPriceLowToHigh,
    sortPriceHighToLow,
    sortSoldCount,
    sortNewest,
    sortScore,
  },
  wishlist: {
    sortManual,
    sortPriceLowToHigh,
    sortPriceHighToLow,
    sortSoldCount,
  },
  default: {
    sortRelevance,
    sortPriceLowToHigh,
    sortPriceHighToLow,
    sortSoldCount,
    sortNewest,
    sortScore,
  },
}

import klarna from '@/assets/img/payment/klarna.png'
// import klarnaMedium from '@/assets/img/payment/klarna_medium.png'
// import paypal from '@/assets/img/payment/paypal.png'
// import paypalSmall from '@/assets/img/payment/paypal-small.png'
// import googlepay from '@/assets/img/payment/googlepay.png'
// import applepay from '@/assets/img/payment/applepay.png'
// import amazon from '@/assets/img/payment/amazon.png'
// import amazonSmall from '@/assets/img/payment/amazon-small.png'

import amazonPayLogo from '@/assets/img/payment-logos/amazon-pay.png'
import payPalLogo from '@/assets/img/payment-logos/paypal.png'
import applePayLogo from '@/assets/img/payment-logos/apple-pay.png'
import googlePayLogo from '@/assets/img/payment-logos/google-pay.png'
import visaLogo from '@/assets/img/payment-logos/visa.png'
import mastercardLogo from '@/assets/img/payment-logos/mastercard.png'
import maestroLogo from '@/assets/img/payment-logos/maestro.png'

// judopay
export const cardPaymentMethod = {
  images: [visaLogo, mastercardLogo, maestroLogo],
  value: 6,
}
// gift card when price 0.00 payment method use that
export const giftCardPaymentMethod = {
  value: 11,
}

export const freePaymentMethod = {
  value: 12,
}

export const paymentMethods = {
  // judopay: {
  //   icon: 'visa',
  //   image: klarna,
  //   value: 6
  // },
  // sagepay: {
  //   imageSmall: klarna,
  //   image: klarna,
  //   value: 4
  // },
  // card: {
  //   image: [visaLogo, mastercardLogo, maestroLogo],
  //   value: 6,
  //   isDefaultPaymentMethod: true,
  //   expressCheckout: false,
  //   expressCheckoutQueue: 1,
  // },
  amazon: {
    image: amazonPayLogo,
    value: 1,
    isDefaultPaymentMethod: false,
    expressCheckout: false,
    showNonUK: true,
  },
  paypal: {
    icon: 'paypal',
    key: 'paypal',
    image: payPalLogo,
    value: 2,
    isDefaultPaymentMethod: false,
    expressCheckout: false,
    expressCheckoutQueue: 4,
    showNonUK: true,
  },
  google: {
    icon: 'google-pay',
    key: 'google-pay',
    image: googlePayLogo,
    value: 5,
    isDefaultPaymentMethod: false,
    expressCheckout: true,
    expressCheckoutQueue: 2,
    showNonUK: true,
  },
  apple: {
    icon: 'apple-pay',
    key: 'apple-pay',
    image: applePayLogo,
    value: 9,
    isDefaultPaymentMethod: false,
    expressCheckout: true,
    expressCheckoutQueue: 3,
    showNonUK: true,
  },
  klarna: {
    icon: 'klarna',
    key: 'klarna',
    image: klarna,
    value: 3,
    showNonUK: false,
  },
  virtual_terminal: {
    icon: 'virtual_terminal',
    key: 'virtual_terminal',
    image: null,
    value: 13,
    showNonUK: true,
  },
}

export const expressPaymentMethods = {
  cardExpress: {
    image: [visaLogo, mastercardLogo],
    value: 6,
    isDefaultPaymentMethod: true,
    expressCheckout: true,
    expressCheckoutQueue: 1,
  },
  google: {
    icon: 'google-pay',
    key: 'google-pay',
    image: googlePayLogo,
    value: 8,
  },
  apple: {
    icon: 'apple-pay',
    key: 'apple-pay',
    image: applePayLogo,
    value: 10,
  },
  other: {
    icon: 'other',
    key: 'other',
    image: [payPalLogo, klarna, amazonPayLogo],
    value: 99,
  },
}

import visa from '@/assets/img/payment/visa.png'
import visaDebit from '@/assets/img/payment/visa_debit.png'
import visaElectron from '@/assets/img/payment/visa_electron.png'
import mastercard from '@/assets/img/payment/mastercard.png'
import maestro from '@/assets/img/payment/maestro.png'
import amex from '@/assets/img/payment/amex.png'
import discover from '@/assets/img/payment/discover.png'
import jcb from '@/assets/img/payment/jcb.png'
import dinersClubInternational from '@/assets/img/payment/diners_club_international.png'
import laser from '@/assets/img/payment/laser.png'
import uatp from '@/assets/img/payment/uatp.png'

export const paymentCards = {
  discover,
  visa,
  visa_debit: visaDebit,
  visa_electron: visaElectron,
  diners_club_international: dinersClubInternational,
  mastercard,
  maestro,
  jcb,
  laser,
  uatp,
  klarna,
  amex,
}

export const orderStatus = {
  waitingInformation: 0, // info
  waitingShipping: 1,
  waitingPayment: 2,
  paying: 3,
  waitingForApproval: 4,
  completed: 5,
  waitingDeliveryInformation: 6, // delivery
}

export const gender = {
  male: 'male',
  female: 'female',
  unisex: 'unisex',
}

export const filterType = {
  price: 0,
  productType: 1,
  gender: 2,
  option: 3,
}

export const filterTypeParams = {
  1: 'product_types',
  2: 'genders',
}

export const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1400,
  // Product detail mobile
  mobile: 991,
  // theme responsive sizes
  tsm: 1024,
  tmd: 1366,
  tlg: 1440,
  txl: 1920,
}

export const mediaWidths = [500, 400, 300, 200, 100]

export const phoneMask = '###########'

/** Currency Change > Country Items **/
export const locales = [
  {
    code: 'en',
    file: 'en-gb/index.js',
    iso: 'en-gb',
    name: 'United Kingdom',
    flag: 'united-kingdom-flag.png',
    symbol: '£',
    currencyCode: 'GBP',
  },
  {
    code: 'en',
    file: 'en-gb/index.js',
    iso: 'en-ie',
    name: 'Ireland',
    flag: 'ireland-flag.png',
    symbol: '€',
    currencyCode: 'EUR',
  },
  {
    code: 'en',
    file: 'en-gb/index.js',
    iso: 'en-us',
    name: 'United States of America',
    flag: 'united-states-of-america-flag.png',
    symbol: '$',
    currencyCode: 'USD',
  },
  {
    code: 'de',
    file: 'de-de/index.js',
    iso: 'de-de',
    name: 'Germany',
    flag: 'germany-flag.png',
    symbol: '€',
    currencyCode: 'EUR',
  },
  {
    code: 'fr',
    file: 'fr-fr/index.js',
    iso: 'fr-fr',
    name: 'France',
    flag: 'france-flag.png',
    symbol: '€',
    currencyCode: 'EUR',
  },
  {
    code: 'es',
    file: 'es-es/index.js',
    iso: 'es-es',
    name: 'Spain',
    flag: 'spain-flag.png',
    symbol: '€',
    currencyCode: 'EUR',
  },
]

export const shippingCountries = [
  {
    code: 'en',
    name: 'United Kingdom',
    flag: 'united-kingdom-flag.png',
    locales: ['en'],
    id: 239,
  },
  {
    code: 'fr',
    name: 'France',
    flag: 'france-flag.png',
    locales: ['fr', 'en-fr'],
    id: 78,
  },
  {
    code: 'de',
    name: 'Deutschland',
    flag: 'germany-flag.png',
    locales: ['de', 'en-de'],
    id: 85,
  },
  {
    code: 'at',
    name: 'Austria',
    flag: 'austria-flag.png',
    locales: ['de-at'],
    id: 15,
  },
  {
    code: 'ie',
    name: 'Ireland',
    flag: 'ireland-flag.png',
    locales: ['ie'],
    id: 110,
  },
  {
    code: 'us',
    name: 'United States of America',
    flag: 'united-states-of-america-flag.png',
    locales: ['us'],
    id: 240,
  },
  {
    code: 'be',
    name: 'Belgique',
    flag: 'belgium-flag.jpg',
    locales: ['en-be', 'fr-be', 'de-be'],
    id: 22,
  },
  {
    code: 'dk',
    name: 'Danmark',
    flag: 'denmark-flag.png',
    locales: ['en-dk'],
    id: 63,
  },
  {
    code: 'se',
    name: 'Sverige',
    flag: 'sweden-flag.png',
    locales: ['en-se'],
    id: 219,
  },
  {
    code: 'ca',
    name: 'Canada',
    flag: 'canada-flag.png',
    locales: ['en-ca'],
    id: 43,
  },
  {
    code: 'ch',
    name: 'Switzerland',
    flag: 'switzerland-flag.png',
    locales: ['en-ch'],
    id: 220,
  },
  {
    code: 'nl',
    name: 'Nederland',
    flag: 'netherlands-flag.png',
    locales: ['en-nl'],
    id: 158,
  },
  {
    code: 'it',
    name: 'Italia',
    flag: 'italy-flag.png',
    locales: ['en-it'],
    id: 113,
  },
  {
    code: 'es',
    name: 'España',
    flag: 'spain-flag.png',
    locales: ['es', 'en-es'],
    id: 212,
  },
]
