import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Accordion: () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c)),
  AddressBook: () => import('../../components/AddressBook.vue' /* webpackChunkName: "components/address-book" */).then(c => wrapFunctional(c.default || c)),
  AddressSelect: () => import('../../components/AddressSelect.vue' /* webpackChunkName: "components/address-select" */).then(c => wrapFunctional(c.default || c)),
  AdminAreaTooltip: () => import('../../components/AdminAreaTooltip.vue' /* webpackChunkName: "components/admin-area-tooltip" */).then(c => wrapFunctional(c.default || c)),
  AdminEditButton: () => import('../../components/AdminEditButton.vue' /* webpackChunkName: "components/admin-edit-button" */).then(c => wrapFunctional(c.default || c)),
  ApiSelect: () => import('../../components/ApiSelect.vue' /* webpackChunkName: "components/api-select" */).then(c => wrapFunctional(c.default || c)),
  Button: () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  CartCheckoutPopup: () => import('../../components/CartCheckoutPopup.vue' /* webpackChunkName: "components/cart-checkout-popup" */).then(c => wrapFunctional(c.default || c)),
  CheckButton: () => import('../../components/CheckButton.vue' /* webpackChunkName: "components/check-button" */).then(c => wrapFunctional(c.default || c)),
  CheckoutBar: () => import('../../components/CheckoutBar.vue' /* webpackChunkName: "components/checkout-bar" */).then(c => wrapFunctional(c.default || c)),
  CodeInputGroup: () => import('../../components/CodeInputGroup.vue' /* webpackChunkName: "components/code-input-group" */).then(c => wrapFunctional(c.default || c)),
  Container: () => import('../../components/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c)),
  CurrencySelector: () => import('../../components/CurrencySelector.vue' /* webpackChunkName: "components/currency-selector" */).then(c => wrapFunctional(c.default || c)),
  DespatchTime: () => import('../../components/DespatchTime.vue' /* webpackChunkName: "components/despatch-time" */).then(c => wrapFunctional(c.default || c)),
  DiscountGiftCardsListing: () => import('../../components/DiscountGiftCardsListing.vue' /* webpackChunkName: "components/discount-gift-cards-listing" */).then(c => wrapFunctional(c.default || c)),
  Divider: () => import('../../components/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c)),
  EmailSignup: () => import('../../components/EmailSignup.vue' /* webpackChunkName: "components/email-signup" */).then(c => wrapFunctional(c.default || c)),
  FormInput: () => import('../../components/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c)),
  HorizontalSlider: () => import('../../components/HorizontalSlider.vue' /* webpackChunkName: "components/horizontal-slider" */).then(c => wrapFunctional(c.default || c)),
  Icon: () => import('../../components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c)),
  ListLoading: () => import('../../components/ListLoading.vue' /* webpackChunkName: "components/list-loading" */).then(c => wrapFunctional(c.default || c)),
  Loading: () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c)),
  LoadingPips: () => import('../../components/LoadingPips.vue' /* webpackChunkName: "components/loading-pips" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  NoImage: () => import('../../components/NoImage.vue' /* webpackChunkName: "components/no-image" */).then(c => wrapFunctional(c.default || c)),
  Notification: () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c)),
  OptionsMap: () => import('../../components/OptionsMap.vue' /* webpackChunkName: "components/options-map" */).then(c => wrapFunctional(c.default || c)),
  OptionsMapGenerator: () => import('../../components/OptionsMapGenerator.vue' /* webpackChunkName: "components/options-map-generator" */).then(c => wrapFunctional(c.default || c)),
  OrderAddress: () => import('../../components/OrderAddress.vue' /* webpackChunkName: "components/order-address" */).then(c => wrapFunctional(c.default || c)),
  OrderSummary: () => import('../../components/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c)),
  PaymentErrorBox: () => import('../../components/PaymentErrorBox.vue' /* webpackChunkName: "components/payment-error-box" */).then(c => wrapFunctional(c.default || c)),
  QuantityCartSelector: () => import('../../components/QuantityCartSelector.vue' /* webpackChunkName: "components/quantity-cart-selector" */).then(c => wrapFunctional(c.default || c)),
  QuantitySelector: () => import('../../components/QuantitySelector.vue' /* webpackChunkName: "components/quantity-selector" */).then(c => wrapFunctional(c.default || c)),
  RadioButton: () => import('../../components/RadioButton.vue' /* webpackChunkName: "components/radio-button" */).then(c => wrapFunctional(c.default || c)),
  RangeSlider: () => import('../../components/RangeSlider.vue' /* webpackChunkName: "components/range-slider" */).then(c => wrapFunctional(c.default || c)),
  RatingStars: () => import('../../components/RatingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(c => wrapFunctional(c.default || c)),
  RatioImage: () => import('../../components/RatioImage.vue' /* webpackChunkName: "components/ratio-image" */).then(c => wrapFunctional(c.default || c)),
  ResponsiveImage: () => import('../../components/ResponsiveImage.vue' /* webpackChunkName: "components/responsive-image" */).then(c => wrapFunctional(c.default || c)),
  SearchNoResult: () => import('../../components/SearchNoResult.vue' /* webpackChunkName: "components/search-no-result" */).then(c => wrapFunctional(c.default || c)),
  SelectOption: () => import('../../components/SelectOption.vue' /* webpackChunkName: "components/select-option" */).then(c => wrapFunctional(c.default || c)),
  Sider: () => import('../../components/Sider.vue' /* webpackChunkName: "components/sider" */).then(c => wrapFunctional(c.default || c)),
  SiderHeader: () => import('../../components/SiderHeader.vue' /* webpackChunkName: "components/sider-header" */).then(c => wrapFunctional(c.default || c)),
  SiderProduct: () => import('../../components/SiderProduct.vue' /* webpackChunkName: "components/sider-product" */).then(c => wrapFunctional(c.default || c)),
  Sticky: () => import('../../components/Sticky.vue' /* webpackChunkName: "components/sticky" */).then(c => wrapFunctional(c.default || c)),
  ThemeImage: () => import('../../components/ThemeImage.vue' /* webpackChunkName: "components/theme-image" */).then(c => wrapFunctional(c.default || c)),
  ThemeResponsiveImage: () => import('../../components/ThemeResponsiveImage.vue' /* webpackChunkName: "components/theme-responsive-image" */).then(c => wrapFunctional(c.default || c)),
  Toggle: () => import('../../components/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c)),
  Tooltip: () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c)),
  VariantOptionItem: () => import('../../components/VariantOptionItem.vue' /* webpackChunkName: "components/variant-option-item" */).then(c => wrapFunctional(c.default || c)),
  ViewButton: () => import('../../components/ViewButton.vue' /* webpackChunkName: "components/view-button" */).then(c => wrapFunctional(c.default || c)),
  CheckoutAddressSummary: () => import('../../components/checkout/AddressSummary.vue' /* webpackChunkName: "components/checkout-address-summary" */).then(c => wrapFunctional(c.default || c)),
  CheckoutCardTypes: () => import('../../components/checkout/CardTypes.vue' /* webpackChunkName: "components/checkout-card-types" */).then(c => wrapFunctional(c.default || c)),
  CheckoutCartFreeProductDiscountPopup: () => import('../../components/checkout/CartFreeProductDiscountPopup.vue' /* webpackChunkName: "components/checkout-cart-free-product-discount-popup" */).then(c => wrapFunctional(c.default || c)),
  CheckoutAdmin: () => import('../../components/checkout/CheckoutAdmin.vue' /* webpackChunkName: "components/checkout-admin" */).then(c => wrapFunctional(c.default || c)),
  CheckoutBag: () => import('../../components/checkout/CheckoutBag.vue' /* webpackChunkName: "components/checkout-bag" */).then(c => wrapFunctional(c.default || c)),
  CheckoutOrderSummary: () => import('../../components/checkout/CheckoutOrderSummary.vue' /* webpackChunkName: "components/checkout-order-summary" */).then(c => wrapFunctional(c.default || c)),
  CheckoutPanel: () => import('../../components/checkout/CheckoutPanel.vue' /* webpackChunkName: "components/checkout-panel" */).then(c => wrapFunctional(c.default || c)),
  CheckoutStepButton: () => import('../../components/checkout/CheckoutStepButton.vue' /* webpackChunkName: "components/checkout-step-button" */).then(c => wrapFunctional(c.default || c)),
  CheckoutStepHeader: () => import('../../components/checkout/CheckoutStepHeader.vue' /* webpackChunkName: "components/checkout-step-header" */).then(c => wrapFunctional(c.default || c)),
  CheckoutSubmitButton: () => import('../../components/checkout/CheckoutSubmitButton.vue' /* webpackChunkName: "components/checkout-submit-button" */).then(c => wrapFunctional(c.default || c)),
  CheckoutDiscountCodeForm: () => import('../../components/checkout/DiscountCodeForm.vue' /* webpackChunkName: "components/checkout-discount-code-form" */).then(c => wrapFunctional(c.default || c)),
  CheckoutExpressPayment: () => import('../../components/checkout/ExpressPayment.vue' /* webpackChunkName: "components/checkout-express-payment" */).then(c => wrapFunctional(c.default || c)),
  CheckoutPaymentCard: () => import('../../components/checkout/PaymentCard.vue' /* webpackChunkName: "components/checkout-payment-card" */).then(c => wrapFunctional(c.default || c)),
  CheckoutPaymentCardField: () => import('../../components/checkout/PaymentCardField.vue' /* webpackChunkName: "components/checkout-payment-card-field" */).then(c => wrapFunctional(c.default || c)),
  CheckoutPaymentFailedMessageBox: () => import('../../components/checkout/PaymentFailedMessageBox.vue' /* webpackChunkName: "components/checkout-payment-failed-message-box" */).then(c => wrapFunctional(c.default || c)),
  CheckoutPaymentMethodRadio: () => import('../../components/checkout/PaymentMethodRadio.vue' /* webpackChunkName: "components/checkout-payment-method-radio" */).then(c => wrapFunctional(c.default || c)),
  CheckoutPaymentMethods: () => import('../../components/checkout/PaymentMethods.vue' /* webpackChunkName: "components/checkout-payment-methods" */).then(c => wrapFunctional(c.default || c)),
  ProductNewCustomerReviews: () => import('../../components/product/NewCustomerReviews.vue' /* webpackChunkName: "components/product-new-customer-reviews" */).then(c => wrapFunctional(c.default || c)),
  ProductAccordion: () => import('../../components/product/ProductAccordion.vue' /* webpackChunkName: "components/product-accordion" */).then(c => wrapFunctional(c.default || c)),
  ProductScore: () => import('../../components/product/ProductScore.vue' /* webpackChunkName: "components/product-score" */).then(c => wrapFunctional(c.default || c)),
  ProductQuestionForm: () => import('../../components/product/QuestionForm.vue' /* webpackChunkName: "components/product-question-form" */).then(c => wrapFunctional(c.default || c)),
  FilterGroup: () => import('../../components/filter/FilterGroup.vue' /* webpackChunkName: "components/filter-group" */).then(c => wrapFunctional(c.default || c)),
  FilterOption: () => import('../../components/filter/FilterOption.vue' /* webpackChunkName: "components/filter-option" */).then(c => wrapFunctional(c.default || c)),
  FilterFilters: () => import('../../components/filter/Filters.vue' /* webpackChunkName: "components/filter-filters" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
